import React from 'react';
import styles from './Modal.module.scss';

interface ModalCloseProps {
  close: () => void;
}

const ModalClose = ({ close }: ModalCloseProps) => {
  return (
    <div className={styles.closeButton}>
      <button type="button" aria-label="Close Modal" onClick={() => close()} />
    </div>
  );
};

export default ModalClose;
