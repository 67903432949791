import React from 'react';
import Button from '../Button/Button';
import ModalBody from '../Modal/ModalBody';
import ModalFooter from '../Modal/ModalFooter';
import ModalHeader from '../Modal/ModalHeader';
import { ActionItemModalProps } from './ActionItemModalProps';

const upperCaseFirstChar = (idx: string) => idx[0].toUpperCase() + idx.slice(1);

/**
 * Modal pop up currently used in
 * common language and product team draft
 * */
const ActionItemModal = ({
  description,
  onConfirm,
  action = 'delete',
  itemType,
  onClose = () => {},
}: ActionItemModalProps) => {
  const desc =
    description && description.length > 120 ? `${description.substring(0, 120)}...` : description;
  const title = `${upperCaseFirstChar(action)} ${itemType}`;

  const handleCloseOnClick = () => {
    onClose();
  };

  const handleConfirmOnClick = async () => {
    await onConfirm();
    onClose();
  };

  return (
    <>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>
        Are you sure you want to
        {` ${action} `}
        &quot;
        {desc}
        &quot;?
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={handleCloseOnClick}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirmOnClick}>
          {upperCaseFirstChar(action)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ActionItemModal;
