import cx from 'clsx';
import React, { HTMLAttributes } from 'react';
import styles from './flexContainer.module.scss';

interface FlexContainerProps extends HTMLAttributes<HTMLDivElement> {
  overflow?: boolean;
}

const FlexContainer = ({ children, className, overflow, ...rest }: FlexContainerProps) => {
  return (
    <div className={cx(styles.container, overflow && styles.overflow, className)} {...rest}>
      {children}
    </div>
  );
};

export default FlexContainer;
