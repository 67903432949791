import React from 'react';

export interface ChecklistItem {
  name: string;
  selected?: boolean;
  items?: ChecklistItem[];
  [key: string]: any;
}

const useChecklist = () => {
  const [checklist, setChecklist] = React.useState([] as ChecklistItem[]);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [hasSelection, setHasSelection] = React.useState(false);

  const selectChildren = (items: ChecklistItem[], selected: boolean) => {
    items.forEach(item => {
      item.selected = selected;
      if (item.items) {
        selectChildren(item.items, selected);
      }
    });
  };

  const selectAll = (selected: boolean) => {
    selectChildren(checklist, selected);
    setChecklist([...checklist]);
  };

  const processSelection = (items: ChecklistItem[]) => {
    items.forEach(item => {
      if (item.items) {
        item.selected = item.items.filter(childItem => !childItem.selected).length === 0;
        processSelection(item.items);
      }
    });
  };

  const selectItem = (item: ChecklistItem, selected: boolean) => {
    item.selected = selected;
    if (item.items) {
      selectChildren(item.items, selected);
    }
    processSelection(checklist);
    setChecklist([...checklist]);
  };

  React.useEffect(() => {
    setIsAllSelected(checklist.filter(item => !item.selected).length === 0);

    let selection = false;
    checklist.forEach(item => {
      if (item.selected) {
        selection = true;
      }
      if (item.items) {
        item.items.forEach(childItem => {
          if (childItem.selected) {
            selection = true;
          }
        });
      }
    });
    setHasSelection(selection);
  }, [setIsAllSelected, checklist]);

  return {
    checklist,
    setChecklist,
    selectItem,
    selectAll,
    isAllSelected,
    hasSelection,
  };
};

export default useChecklist;
