import React from 'react';
import Button from '../Button/Button';
import { ModalBody, ModalFooter, ModalHeader } from '../Modal';

// Need to refactor commonLanguageStore
// to remove redundant prop data
type InnerProps = {
  close: () => void;
  submitData: () => void;
};

const ReviewModal: React.FC<InnerProps> = ({ close, submitData }) => {
  return (
    <>
      <ModalHeader onClose={close}>Complete Review</ModalHeader>
      <ModalBody>Are you sure you want to complete the review?</ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            close();
            submitData();
          }}
        >
          Complete
        </Button>
      </ModalFooter>
    </>
  );
};

export default ReviewModal;
