import { ContentState } from '../rteToHtml/types';

const cleanUpRte = (value: string | null | undefined): string => {
  if (!value) {
    return '';
  }
  const styles: { [style: string]: true } = { BOLD: true, ITALIC: true, UNDERLINE: true };

  try {
    const contentState: { content: ContentState } = JSON.parse(value);

    if (contentState.content?.entityMap) {
      Object.values(contentState.content.entityMap).forEach(map => {
        // if there is a link entity then we want to make sure there is no href key and only a url key (for storing the url)
        const contentMap = map;
        if (contentMap.type === 'LINK' && contentMap.data.href) {
          if (!contentMap.data.url) {
            contentMap.data.url = contentMap.data.href;
          }
          delete contentMap.data.href;
        }
      });
      contentState.content.blocks.forEach(block => {
        block.inlineStyleRanges.forEach(style => {
          if (styles[style.style]) {
            // we define the start of the bold/underline/italic to be when there is an actual character (i.e. not a space)
            while (block.text[style.offset] === ' ' && style.length > 0) {
              style.offset += 1;
              style.length -= 1;
            }
            // the end of a bold/underline/italics will be when there isnt a space
            while (block.text[style.offset + style.length - 1] === ' ' && style.length > 0) {
              style.length -= 1;
            }
          }
        });
        // if any styles do not have a length (e.g. a bunch of spaces were bolded) then we want to drop them from the inline style ranges
        block.inlineStyleRanges = block.inlineStyleRanges.filter(style => style.length > 0);
      });
    }
    return JSON.stringify(contentState);
  } catch (e) {
    return value;
  }
};

export default cleanUpRte;
