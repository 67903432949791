import cx from 'clsx';
import React, { InputHTMLAttributes } from 'react';
import styles from './toggle.module.scss';

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  onToggle: (value: boolean) => void;
}

const Toggle = ({ id, onToggle, ...rest }: ToggleProps) => {
  return (
    <label htmlFor={id} className={styles.switch}>
      <input id={id} type="checkbox" onChange={e => onToggle(e.currentTarget.checked)} {...rest} />
      <span className={cx([styles.slider, styles.round])} />
    </label>
  );
};

export default Toggle;
