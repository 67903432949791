import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const CheckMessageToast = ({ message }: { message: string }) => {
  return (
    <>
      <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faCheckCircle} />
      {message}
    </>
  );
};

export default CheckMessageToast;
