import { useState } from "react";

const useIsLoading = () => {
  const [isLoading, setIsLoading] = useState(false);

  const initLoading = async (callback: () => void) => {
    setIsLoading(true);
    await callback();
    setIsLoading(false);
  };

  return {
    isLoading,
    initLoading,
  };
};

export default useIsLoading;
