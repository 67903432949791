import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './TableDragIcon.module.scss';

const TableDragIcon:React.FC = () => {
  return <FontAwesomeIcon className={styles.dragIcon} icon={faArrowsAlt} />;
};

export default TableDragIcon;
