import React, { ReactNode } from 'react';
import { Button } from '../Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';
import { ModalProps } from '../Modal/Modal';

interface ConfirmModalProps extends ModalProps {
  headerText: ReactNode;
  bodyText: ReactNode;
  onConfirm: () => void;
  confirmButtonText?: string;
}

const ConfirmModal = ({
  open,
  size,
  onClose,
  headerText,
  bodyText,
  onConfirm,
  confirmButtonText = 'Save',
}: ConfirmModalProps) => {
  return (
    <Modal size={size} open={open} onClose={onClose}>
      <ModalHeader onClose={onClose}>{headerText}</ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
