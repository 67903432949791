import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ButtonHTMLAttributes } from 'react';
import styles from './SideMenu.module.scss';
import {
  AgencyIcon,
  ChangeLogIcon,
  ColorFamIcon,
  DisclaimerIcon,
  CommonLangIcon,
  DphIcon,
  GstIcon,
  HelpIcon,
  NatIcon,
  ProductTeamIcon,
  ReportsIcon,
  ReviewIcon,
  SeriesCatIcon,
  SpanishIcon,
  CollapseNavArrowIcon,
  ExpandNavArrowIcon,
  LogoutIcon,
} from './icons';

const IconMap = {
  agency: AgencyIcon,
  changelog: ChangeLogIcon,
  colorFam: ColorFamIcon,
  disclaimer: DisclaimerIcon,
  commonLang: CommonLangIcon,
  dph: DphIcon,
  gst: GstIcon,
  help: HelpIcon,
  nat: NatIcon,
  productTeam: ProductTeamIcon,
  reports: ReportsIcon,
  review: ReviewIcon,
  seriesCat: SeriesCatIcon,
  spanish: SpanishIcon,
  expandNavArrow: ExpandNavArrowIcon,
  collapseNavArrow: CollapseNavArrowIcon,
  logout: LogoutIcon,
};

interface SideMenuIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconDefinition | keyof typeof IconMap;
}

const SideMenuIcon = ({ className, icon, children, ...rest }: SideMenuIconProps) => {
  const IconComponent = typeof icon === 'string' ? IconMap[icon] : null;

  return (
    <button type="button" className={styles.sideMenuIcon} {...rest}>
      {IconComponent && <IconComponent />}
      {icon && typeof icon !== 'string' && <FontAwesomeIcon icon={icon} />}
      {children && <span className={styles.sideMenuChildren}>{children}</span>}
    </button>
  );
};

export default SideMenuIcon;
