import cx from 'clsx';
import React from 'react';
import RTEditor, { RTEditorProps } from '../RTEditor';
import styles from './singleLineRTEditor.module.scss';

interface SingleLineRTEditorProps extends RTEditorProps {}

const SingleLineRTEditor = ({ editorStyles, ...rest }: SingleLineRTEditorProps) => {
  return <RTEditor editorStyles={cx(styles.root, editorStyles)} {...rest} />;
};

export default SingleLineRTEditor;
