import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Header from '../Header/Header';
import styles from './HelpView.module.scss';

const HelpView = () => {
  return (
    <>
      <Header moduleTitle="" moduleSubTitle="Help" />
      <section>
        <ul className={styles.linksWrapper}>
          <li>
            <span>A link to the Confluence how to articles:</span>
            <ul className={styles.linksWrapper}>
              <li>
                <a
                  target="_blank"
                  href="https://confluence.sdlc.toyota.com/pages/viewpage.action?spaceKey=VAPI&title=11.+How-To+Articles"
                  rel="noopener noreferrer"
                >
                  VAPI Wiki
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span>The VAPI help desk information:</span>
            <ul className={styles.linksWrapper}>
              <li>
                <span>
                  (
                  <a href="mailto:vapi@fiveanddone.com">
                    {'vapi@fiveanddone.com '}
                    <FontAwesomeIcon className={styles.envelope} icon={faEnvelope} />
                  </a>
                  ) / 888-322-VAPI(8274)
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </section>
    </>
  );
};

export default HelpView;
