import React, { SVGProps } from 'react';

const ReviewIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.589 26.253L25.487 24.152C25.9298 23.4761 26.2014 22.7025 26.2783 21.8981C26.3552 21.0937 26.2352 20.2826 25.9285 19.535C25.6218 18.7874 25.1378 18.1257 24.5181 17.607C23.8985 17.0883 23.1619 16.7283 22.372 16.558L22.339 16.552V12.483C22.3392 12.2471 22.2567 12.0185 22.106 11.837L22.107 11.839V11.834C22.0881 11.8111 22.068 11.789 22.047 11.768L22.039 11.759L22.012 11.732L14.572 4.292C14.5513 4.2713 14.5296 4.25161 14.507 4.233L14.481 4.215C14.4648 4.20178 14.4481 4.18911 14.431 4.177L14.406 4.159L14.352 4.125L14.329 4.113C14.3063 4.09967 14.2813 4.08733 14.254 4.076L14.222 4.063L14.171 4.045L14.135 4.034L14.077 4.019L14.049 4.013C14.0206 4.00713 13.9919 4.00279 13.963 4H5.015C4.74665 4.00105 4.48954 4.10791 4.2995 4.29738C4.10946 4.48686 4.00184 4.74365 4 5.012V25.389C4.00026 25.6585 4.10716 25.9169 4.29733 26.1078C4.48751 26.2988 4.74552 26.4067 5.015 26.408H21.325C22.3001 26.4097 23.2537 26.1219 24.065 25.581L24.047 25.592L26.149 27.694C26.3428 27.8703 26.597 27.9652 26.859 27.959C27.1209 27.9527 27.3703 27.8459 27.5555 27.6605C27.7407 27.4752 27.8474 27.2257 27.8534 26.9638C27.8595 26.7019 27.7644 26.4477 27.588 26.254L27.589 26.255V26.253ZM24.264 21.426C24.2641 21.8125 24.188 22.1953 24.0401 22.5525C23.8923 22.9096 23.6755 23.2341 23.4022 23.5075C23.1289 23.7809 22.8045 23.9978 22.4474 24.1457C22.0903 24.2937 21.7075 24.3699 21.321 24.37C20.9345 24.3701 20.5517 24.294 20.1945 24.1461C19.8374 23.9983 19.5129 23.7815 19.2395 23.5082C18.9661 23.2349 18.7492 22.9105 18.6013 22.5534C18.4533 22.1963 18.3771 21.8135 18.377 21.427C18.3767 20.6463 18.6866 19.8975 19.2384 19.3453C19.7902 18.7931 20.5388 18.4828 21.3195 18.4825C22.1002 18.4822 22.849 18.7921 23.4012 19.3439C23.9534 19.8957 24.2637 20.6443 24.264 21.425V21.426ZM14.868 7.47L18.861 11.464H14.868V7.47ZM6.038 6.03H12.831V12.483C12.831 13.046 13.287 13.502 13.85 13.502H20.303V16.552C18.025 17.039 16.341 19.035 16.341 21.425C16.341 22.534 16.703 23.558 17.316 24.385L17.306 24.372H6.037L6.038 6.03Z"
        fill="white"
      />
    </svg>
  );
};

export default ReviewIcon;
