import { AttributedString, CSInlineStyleRanges } from '../types';
import styleMap from './styleMap';

const processInlineStyleRanges = (
  attrString: AttributedString[],
  inlineStyleRanges: CSInlineStyleRanges[]
) => {
  inlineStyleRanges.forEach(styleRange => {
    const startIndex = styleRange.offset;
    const endIndex = styleRange.offset + (styleRange.length - 1);
    const sMap = styleMap[styleRange.style];
    if (!sMap) {
      return;
    }

    attrString.forEach(string => {
      // start filling inline style range data
      if (string.index === startIndex) {
        string.startingTags = [`<${sMap.tag}>`, ...string.startingTags];
      }

      if (string.index === endIndex) {
        string.endingTags = [...string.endingTags, `</${sMap.tag}>`];
      }
    });
  });
};

export default processInlineStyleRanges;
