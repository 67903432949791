import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Input from '../Input/Input';
import styles from './Accordion.module.scss';
import AccordionHeader from './AccordionHeader';
import { AccordionItemProps } from './AccordionProps';
import AccordionSection from './AccordionSection';

const AccordionItem = ({
  seriesCategory,
  seriesCategoryES,
  seriesCategorySeriesId,
  section,
  className,
  hasSeriesDetails,
  modalHeaderText,
  modalBodyText,
  confirmButtonText,
  onEdit,
  onDelete,
}: AccordionItemProps) => {
  const [expand, setExpand] = useState(false);
  const [edit, setEdit] = useState(false);
  const [del, setDelete] = useState(false);
  const [seriesCat, setSeriesCat] = useState(seriesCategory);
  const [seriesCatES, setSeriesCatES] = useState(seriesCategoryES);
  const [seriesCatSeriesId, setSeriesCatSeriesId] = useState(seriesCategorySeriesId);

  useEffect(() => {
    setSeriesCat(seriesCategory);
    setSeriesCatES(seriesCategoryES);
    setSeriesCatSeriesId(seriesCategorySeriesId);
  }, [seriesCategory, seriesCategoryES, seriesCategorySeriesId]);

  const handleOnToggle = (value: boolean) => {
    setExpand(value);
  };

  const handleDelete = () => {
    setDelete(!del);
  };

  return (
    <div className={cx(className, styles.accordionItem, { [styles.shadow]: !expand })}>
      <AccordionHeader expand={expand} onToggle={handleOnToggle}>
        <span className={styles.actionItems}>
          {!edit && onDelete && !(del && hasSeriesDetails) && (
            <FontAwesomeIcon className={styles.trash} icon={faTrash} onClick={handleDelete} />
          )}
          {onEdit && !edit && (
            <FontAwesomeIcon className={styles.edit} icon={faPen} onClick={() => setEdit(!edit)} />
          )}
        </span>
        {edit ? (
          <div className={styles.inputRow}>
            <Input
              className={styles.inputBox}
              name={seriesCat}
              defaultValue={seriesCat}
              onBlur={event => {
                setSeriesCat(event.currentTarget.value);
              }}
            />
            <Input
              className={styles.inputBox}
              name={seriesCatES}
              defaultValue={seriesCatES}
              onBlur={event => {
                setSeriesCatES(event.currentTarget.value);
              }}
            />
            <Input
              className={styles.inputBox}
              name={seriesCatSeriesId}
              defaultValue={seriesCatSeriesId}
              onBlur={event => {
                setSeriesCatSeriesId(event.currentTarget.value);
              }}
            />

            <div className={styles.cta}>
              <Button
                variant="transparent"
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (onEdit) {
                    onEdit(seriesCat, seriesCatES, seriesCatSeriesId);
                    setEdit(!edit);
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.header}>
            <span>{seriesCategory}</span>
            <span>{seriesCategoryES}</span>
            <span>{seriesCategorySeriesId}</span>
          </div>
        )}
      </AccordionHeader>
      <AccordionSection expand={expand}>{section}</AccordionSection>
      <ConfirmModal
        headerText={modalHeaderText}
        bodyText={modalBodyText}
        confirmButtonText={confirmButtonText}
        open={del}
        onClose={() => setDelete(false)}
        onConfirm={() => onDelete && onDelete(true)}
      />
    </div>
  );
};

export default AccordionItem;
