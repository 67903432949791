import {
  faArchive,
  faArrowsAlt,
  faCopy,
  faEdit,
  faEye,
  faEyeSlash,
  faFlag,
  faLeaf,
  faTrash,
  faChevronUp,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import styles from './ActionButton.module.scss';
import { ActionButtonProps } from './ActionButtonProps';

const renderIcon = (icon: string) => {
  switch (icon) {
    case 'trash':
      return <FontAwesomeIcon icon={faTrash} />;
    case 'archive':
      return <FontAwesomeIcon icon={faArchive} />;
    case 'edit':
      return <FontAwesomeIcon icon={faEdit} />;
    case 'flag':
      return <FontAwesomeIcon icon={faFlag} />;
    case 'preview':
      return <FontAwesomeIcon icon={faEye} />;
    case 'nopreview':
      return <FontAwesomeIcon icon={faEyeSlash} />;
    case 'leaf':
      return <FontAwesomeIcon icon={faLeaf} />;
    case 'copy':
      return <FontAwesomeIcon icon={faCopy} />;
    case 'arrows':
      return <FontAwesomeIcon icon={faArrowsAlt} />;
    case 'arrowUp':
      return <FontAwesomeIcon icon={faChevronUp} />;
    case 'arrowDown':
      return <FontAwesomeIcon icon={faChevronDown} />;
    case 'arrowRight':
      return <FontAwesomeIcon icon={faChevronRight} />;
    default:
      return null;
  }
};

const ActionButton = ({ className, icon, selected, ...rest }: ActionButtonProps) => {
  return (
    <button
      type="button"
      className={cx(styles.actionBtn, selected && [styles.selected], className)}
      {...rest}
    >
      {renderIcon(icon)}
    </button>
  );
};

export default ActionButton;
