import cx from 'clsx';
import React from 'react';
import { ButtonProps } from '../Button/ButtonProps';
import styles from './Dropdown.module.scss';

export interface DropdownButtonProps extends ButtonProps {
  disabled?: boolean;
  error?: boolean;
}

const DropdownButton = (props: DropdownButtonProps) => {
  const { className, error, ...rest } = props;

  return (
    <button
      type="button"
      className={cx([error && styles.error, styles.button, className])}
      {...rest}
    />
  );
};

export default DropdownButton;
