import {
  faCircle,
  faCopy,
  faDownload,
  faFlag,
  faLink,
  faPen,
  faPlus,
  faRandom,
  faSync,
  faThumbsUp,
  faTimesCircle,
  faTrash,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { useState } from 'react';
import { Button } from '../Button';
import Popover from '../Popover';
import styles from './IconTextButton.module.scss';

// think about just passing through icon name directly rather than mapping,
// and matching class to same icon name. Switch will then become unnecessary.
const renderIcon = (icon: string) => {
  switch (icon) {
    case 'flag': {
      return <FontAwesomeIcon className={styles.iconFlag} icon={faFlag} />;
    }
    case 'plus': {
      return <FontAwesomeIcon className={styles.iconPlus} icon={faPlus} />;
    }
    case 'undo': {
      return <FontAwesomeIcon className={styles.iconUndo} icon={faUndo} />;
    }
    case 'circle': {
      return <FontAwesomeIcon className={styles.iconCircle} icon={faCircle} />;
    }
    case 'redCircle': {
      return <FontAwesomeIcon className={styles.redIconCircle} icon={faCircle} />;
    }
    case 'purpleCircle': {
      return <FontAwesomeIcon className={styles.purpleIconCircle} icon={faCircle} />;
    }
    case 'random': {
      return <FontAwesomeIcon className={styles.iconRandom} icon={faRandom} />;
    }
    case 'remove': {
      return <FontAwesomeIcon className={styles.iconRemove} icon={faTimesCircle} />;
    }
    case 'trash': {
      return <FontAwesomeIcon className={styles.iconTrash} icon={faTrash} />;
    }
    case 'thumbsUp': {
      return <FontAwesomeIcon className={styles.iconThumbsUp} icon={faThumbsUp} />;
    }
    case 'sync': {
      return <FontAwesomeIcon className={styles.iconSync} icon={faSync} />;
    }
    case 'edit': {
      return <FontAwesomeIcon className={styles.iconEdit} icon={faPen} />;
    }
    case 'duplicate': {
      return <FontAwesomeIcon className={styles.iconDuplicate} icon={faCopy} />;
    }
    case 'download': {
      return <FontAwesomeIcon className={styles.iconDownload} icon={faDownload} />;
    }
    case 'link': {
      return <FontAwesomeIcon className={styles.iconLink} icon={faLink} />;
    }
    default: {
      return null;
    }
  }
};

interface IconTextButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon:
    | 'flag'
    | 'plus'
    | 'undo'
    | 'circle'
    | 'redCircle'
    | 'purpleCircle'
    | 'remove'
    | 'edit'
    | 'thumbsUp'
    | 'sync'
    | 'duplicate'
    | 'download'
    | 'random'
    | 'link'
    | 'trash';
  text?: string;
  tooltipText?: string;
  smallIcon?: boolean;
  textClassName?: string;
  tooltip?: string;
  align?: 'right' | 'left';
  customIconOverride?: React.ReactNode;
}

const IconTextButton = (props: IconTextButtonProps) => {
  const {
    className,
    icon,
    text,
    smallIcon,
    tooltip,
    align,
    tooltipText,
    textClassName,
    customIconOverride,
    ...rest
  } = props;

  const [tooltipHover, setTooltipHover] = useState(false);

  const onSyncUpdatesHover = (isOn: boolean) => {
    setTooltipHover(isOn);
  };

  return (
    <Button
      type="button"
      className={cx([styles.button, className])}
      {...rest}
      onMouseOver={() => {
        onSyncUpdatesHover(true);
      }}
      onMouseOut={() => {
        onSyncUpdatesHover(false);
      }}
    >
      {icon && (
        <span
          className={cx([
            {
              [styles.icon]: true,
              [styles.smallIcon]: smallIcon,
            },
          ])}
        >
          {customIconOverride ?? renderIcon(icon)}
        </span>
      )}
      <span className={cx(textClassName)}>{text}</span>
      {tooltipHover && tooltipText && (
        <div className={styles.popoverContainer}>
          <Popover
            popoverElementClass={styles.popover}
            align={align}
            defaultOpen
            toggleElement={<></>}
            popoverElement={<span className={styles.tooltipText}>{tooltipText}</span>}
          />
        </div>
      )}
    </Button>
  );
};

export default IconTextButton;
