import { AttributedString } from '../types';

const convertAttributedString = (value: string): AttributedString[] => {
  return value.split('').map((char, index) => ({
    char,
    index,
    startingTags: [],
    endingTags: [],
  }));
};

export default convertAttributedString;
