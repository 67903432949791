import cx from 'clsx';
import React from 'react';
import styles from './Select.module.scss';

const Select = (props: React.HTMLAttributes<HTMLSelectElement>) => {
  const { className, children, ...rest } = props;

  return (
    <select className={cx([className, styles.select])} {...rest}>
      {children}
    </select>
  );
};

export default Select;
