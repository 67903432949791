import cx from 'clsx';
import React from 'react';
import { useComponentVisible } from '../../hooks';
import onEnterPress from '../../utils/events/onEnterPress/onEnterPress';
import Dropdown from '../Dropdown/Dropdown';
import DropdownButton from '../Dropdown/DropdownButton';
import DropdownContent from '../Dropdown/DropdownContent';
import styles from './DropdownList.module.scss';

interface DropdownListProps {
  value: any;
  onSelect?: (item: string) => void;
  contentElement?(ref: any, open: boolean, closeDropdown: () => void): JSX.Element;
  list?: Array<string>;
  onClose?: () => void;
  placeholder?: string;
  required?: boolean;
  btnClass?: string;
  align?: string;
  disabled?: boolean;
  className?: string;
}

const DropdownList = ({
  value,
  onSelect = () => {},
  contentElement,
  list = [],
  onClose = () => {},
  placeholder,
  required,
  btnClass,
  align,
  disabled = false,
  className,
}: DropdownListProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const hasError = () => {
    if (required && !value) {
      return true;
    }
    return false;
  };

  const handleOnSelect = (item: string) => {
    onSelect(item);
    onClose();
    setIsComponentVisible(false);
  };

  return (
    <Dropdown className={className}>
      <DropdownButton
        onClick={() => {
          if (!disabled) setIsComponentVisible(true);
        }}
        variant={hasError() ? 'error' : undefined}
        className={cx(hasError() && styles.error, !value && styles.placeholder, btnClass)}
        disabled={disabled}
      >
        {!value ? placeholder : value}
      </DropdownButton>
      {contentElement ? (
        contentElement(ref, isComponentVisible, () => setIsComponentVisible(false))
      ) : (
        <DropdownContent align={align} open={isComponentVisible} ref={ref}>
          <ul className={styles.dropdownList}>
            {list &&
              list.map(item => (
                <li
                  key={item}
                  role="menuitem"
                  onClick={() => handleOnSelect(item)}
                  onKeyPress={e => onEnterPress(e, () => handleOnSelect(item))}
                >
                  {item}
                </li>
              ))}
          </ul>
        </DropdownContent>
      )}
    </Dropdown>
  );
};

export default DropdownList;
