import React from 'react';
import MultiLineRTEditor from '../../RTEditor/MultiLineRTEditor';
import { SuggestionType } from '../../RTEditor/RTEditorReducer';
import SingleLineRTEditor from '../../RTEditor/SingleLineRTEditor';
import { TableCell } from '../../Table';
import { TableColType } from '../../Table/components/TableCell/tableTypes';

interface RichTextCellProps {
  colType: TableColType;
  value: string;
  border?: boolean;
  cellStyles?: string;
  disabled?: boolean;
  placeholder?: string;
  singleLine?: boolean;
  suggestionTypes?: SuggestionType[];
  onBlur?(value: string): void;
  required?: boolean;
}

const RichTextCell = ({
  colType,
  border,
  cellStyles,
  singleLine,
  onBlur,
  required,
  ...editorProps
}: RichTextCellProps) => {
  const handleOnBlur = (value: string) => {
    if (onBlur) {
      onBlur(value);
    }
  };

  const Component = singleLine ? SingleLineRTEditor : MultiLineRTEditor;

  return (
    <TableCell className={cellStyles} colType={colType} border={border} noSpan>
      <Component required={required} onBlur={handleOnBlur} {...editorProps} />
    </TableCell>
  );
};

export default RichTextCell;
