import React, { SVGProps } from 'react';

const AgencyIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_289_1288"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="6"
        width="25"
        height="21"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 16C10.2386 16 8 13.7614 8 11C8 8.23858 10.2386 6 13 6C15.7614 6 18 8.23858 18 11C18 13.7614 15.7614 16 13 16ZM22 25V23C22 20.2386 19.7614 18 17 18H9C6.23858 18 4 20.2386 4 23V25C4 25.5523 4.44772 26 5 26C5.55228 26 6 25.5523 6 25V23C6 21.3431 7.34315 20 9 20H17C18.6569 20 20 21.3431 20 23V25C20 25.5523 20.4477 26 21 26C21.5523 26 22 25.5523 22 25ZM28.0002 23.0001V25.0001C28.0002 25.5524 27.5525 26.0001 27.0002 26.0001C26.448 26.0001 26.0002 25.5524 26.0002 25.0001V23.0009C25.9992 21.6337 25.074 20.4402 23.7502 20.0984C23.2155 19.9603 22.8939 19.4149 23.032 18.8801C23.1701 18.3454 23.7155 18.0238 24.2502 18.1619C26.4565 18.7315 27.9985 20.7208 28.0002 23.0001ZM19.7522 8.09887C21.0796 8.43875 22.0081 9.63487 22.0081 11.0051C22.0081 12.3754 21.0796 13.5715 19.7522 13.9114C19.2172 14.0484 18.8945 14.5931 19.0315 15.1282C19.1685 15.6632 19.7133 15.9859 20.2483 15.8489C22.4607 15.2824 24.0081 13.2889 24.0081 11.0051C24.0081 8.72136 22.4607 6.72784 20.2483 6.16137C19.7133 6.02439 19.1685 6.34706 19.0315 6.88208C18.8945 7.41711 19.2172 7.96189 19.7522 8.09887ZM16 11C16 12.6569 14.6569 14 13 14C11.3431 14 10 12.6569 10 11C10 9.34315 11.3431 8 13 8C14.6569 8 16 9.34315 16 11Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_289_1288)">
        <rect x="4" y="4" width="24" height="24" fill="white" />
      </g>
    </svg>
  );
};

export default AgencyIcon;
