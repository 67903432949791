import cx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';
import { HeaderButton } from '../../Table';
import { TableColType } from '../TableCell/tableTypes';
import styles from './HeaderCell.module.scss';

interface HeaderCellProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  cellClassName?: string;
  border?: boolean;
  colType?: TableColType;
  rowSpan?: number;
}

const HeaderCell: React.FC<HeaderCellProps> = ({
  onClick,
  cellClassName,
  border,
  children,
  colType,
  rowSpan,
  ...rest
}) => (
  <td
    rowSpan={rowSpan}
    className={cx(
      styles.headerCell,
      cellClassName,
      border && styles.border,
      colType && styles[`colType-${colType}`]
    )}
  >
    {onClick && (
      <HeaderButton onClick={onClick} {...rest}>
        {children}
      </HeaderButton>
    )}
    {!onClick && children}
  </td>
);

export default HeaderCell;
