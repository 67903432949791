import React from 'react';
import { IconTextButton } from '../IconTextButton';
import { Notes } from '../Notes';
import Popover from '../Popover';

type Props = {
  notes: string;
  readOnly?: boolean;
  handleUpdateItem: (notes: string) => void;
  textButtonClassName?: string;
  popoverClass?: string;
  align?: 'right' | 'left';
  defaultOpen?: boolean;
  icon?: 'circle' | 'purpleCircle';
  iconText?: string;
  tabIndex?: number;
  toggleElement?: JSX.Element; // Overrides default toggle Element
};

const NotesPopover: React.FC<Props> = ({
  notes,
  readOnly,
  popoverClass,
  handleUpdateItem,
  textButtonClassName,
  align = 'right',
  defaultOpen = false,
  icon = 'circle',
  iconText = 'Notes',
  tabIndex,
  toggleElement,
}) => {
  if (notes && readOnly) {
    return (
      <Popover
        popoverClass={popoverClass}
        align={align}
        defaultOpen={defaultOpen}
        toggleElement={
          toggleElement || (
            <IconTextButton tabIndex={tabIndex} smallIcon icon={icon} text={iconText} className={textButtonClassName} />
          )
        }
        popoverElement={<Notes readOnly notes={notes} />}
      />
    );
  } if (readOnly) {
    return <></>;
  }
  return (
    <Popover
      popoverClass={popoverClass}
      align={align}
      defaultOpen={defaultOpen}
      toggleElement={
        toggleElement || (
          <IconTextButton
            tabIndex={tabIndex}
            className={textButtonClassName}
            smallIcon
            icon={notes ? 'circle' : 'plus'}
            text={iconText}
          />
        )
      }
      popoverElement={(
        <Notes
          notes={notes}
          onUpdate={(notes1) => {
            handleUpdateItem(notes1);
          }}
        />
      )}
    />
  );
};

export default NotesPopover;
