import React from 'react';
import Button from '../Button/Button';
import { ModalBody, ModalFooter, ModalHeader } from '../Modal';

// Need to refactor commonLanguageStore
// to remove redundant prop data
type InnerProps = {
  close: () => void;
  publishData: () => void;
};

const PublishModal: React.FC<InnerProps> = ({ close, publishData }) => {
  return (
    <>
      <ModalHeader onClose={close}>Publish Draft</ModalHeader>
      <ModalBody>Are you sure you want to publish this draft?</ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            close();
            publishData();
          }}
        >
          Publish
        </Button>
      </ModalFooter>
    </>
  );
};

export default PublishModal;
