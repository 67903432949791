import React, { useState } from 'react';
import { ActionButton, Button } from '../..';
import styles from './DropdownEditItem.module.scss';

interface DropdownEditItemProps {
  value: string;
  isSelected: boolean;
  onSelect: () => void;
  onEdit?: (oldValue: string, newValue: string) => void;
  onClose?: () => void;
}

const DropdownEditItem = ({
  value,
  isSelected,
  onEdit,
  onSelect,
  onClose,
}: DropdownEditItemProps) => {
  const [editOpen, setEditOpen] = useState(false);
  const [itemVal, setItemVal] = useState(value);
  const [inputVal, setInputVal] = useState(value);

  const handleOnEdit = () => {
    if (inputVal && inputVal.trim() && value !== inputVal.trim()) {
      setItemVal(inputVal.trim());
      if (typeof onEdit === 'function') { onEdit(value, inputVal.trim()) };
      setEditOpen(false);
    }
  };

  return (
    <li className={isSelected ? styles.selected : ''}>
      {!editOpen ? (
        <>
          <div
            className={styles.value}
            onKeyPress={() => {
              onSelect();
              if (typeof onClose === 'function') { onClose(); }
            }}
            onClick={() => {
              onSelect();
              if (typeof onClose === 'function') { onClose(); }
            }}
            role="button"
            tabIndex={0}
          >
            {itemVal}
          </div>
          {onEdit && (
            <div className={styles.editBtn}>
              <ActionButton
                icon="edit"
                onClick={() => {
                  setEditOpen(true);
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className={styles.inputGroup}>
          <input
            className={styles.input}
            type="text"
            value={inputVal}
            onChange={e => setInputVal(e.currentTarget.value)}
          />
          <Button className={styles.inputBtn} onClick={() => handleOnEdit()}>
            Save
          </Button>
        </div>
        )}
    </li>
  );
};

export default DropdownEditItem;
