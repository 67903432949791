import { AttributedString, ContentState } from './types';
import convertAttributedString from './util/convertAttributedString';
import getBlockType from './util/getBlockType';
import processBlockType from './util/processBlockType';
import processInlineStyleRanges from './util/processeInlineStyleRanges';
import processEntityRanges from './util/processEntityRanges';

const generateHtml = (contentState: ContentState) => {
  const arryOfAttrStrings: AttributedString[][] = [];
  let lastWrapperTag: string | null = null;

  contentState.blocks.forEach((block, index) => {
    const attrString = convertAttributedString(block.text);
    processInlineStyleRanges(attrString, block.inlineStyleRanges);
    processEntityRanges(attrString, block.entityRanges, contentState.entityMap);

    const blockType = getBlockType(block.type);
    if (blockType !== null) {
      lastWrapperTag = processBlockType(
        attrString,
        blockType,
        lastWrapperTag,
        index === contentState.blocks.length - 1
      );
    }

    arryOfAttrStrings.push(attrString);
  });

  return arryOfAttrStrings
    .map(attrStrings => {
      const stringVal = `${attrStrings
        .map(
          attrString =>
            `${attrString.startingTags.join('')}${attrString.char}${attrString.endingTags.join('')}`
        )
        .join('')}`;
      return `${stringVal}`;
    })
    .join('<br>');
};

export default generateHtml;
