import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './Toast.module.scss';

export const Toast = () => <section className={styles.toast}> </section>;

export const CheckMessageToast = ({ message }: { message: string }) => {
  return (
    <>
      <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faCheckCircle} />
      {message}
    </>
  );
};

export default Toast;
