import cx from 'clsx';
import React, { ReactNode, useState } from 'react';
import IconTextButton from '../../../IconTextButton/IconTextButton';
import { Modal } from '../../../Modal';
import AddModelYearModal from '../AddModelYearModal/AddModelYearModal';
import RemoveSeriesModal from '../RemoveSeriesModal';
import styles from './VehicleTable.module.scss';

interface VehicleTableProps {
  series: string;
  seriesId: string;
  modelYears?: string[];
  children?: ReactNode;
  onAddItem: (seriesId: string, modelYear: number) => void;
  onDuplicateItem: (seriesId: string, fromModelYear: number, toModelYear: number) => void;
  onRemoveSeries: (seriesId: string) => void;
  canAddYear?: boolean;
  canRemoveYear?: boolean;
  isSpanish?: boolean;
  statusColumn?: boolean;
  outOfSyncColumn?: boolean;
  adminOutOfSyncColumn?: boolean;
  className?: string;
}

const VehicleTable = ({
  series,
  seriesId,
  children,
  onAddItem,
  onDuplicateItem,
  onRemoveSeries,
  canAddYear,
  modelYears = [],
  canRemoveYear = true,
  isSpanish = false,
  statusColumn,
  outOfSyncColumn,
  adminOutOfSyncColumn,
  className,
}: VehicleTableProps) => {
  const [removeSeries, setRemoveSeries] = useState(false);
  const [addModel, setaddModel] = useState(false);

  return (
    <table className={cx(styles.table, className)}>
      <thead>
        <tr>
          <td>
            <button type="button" className={cx(styles.cta, styles.seriesName)}>
              {series}
            </button>
          </td>
          <td>
            <button type="button" className={styles.cta}>
              Date published
            </button>
          </td>
          <td>
            <button type="button" className={styles.cta}>
              Version
            </button>
          </td>
          <td>{isSpanish ? 'ES' : ''}</td>
          <td>{isSpanish ? 'EN' : ''}</td>

          {statusColumn && <td>Status</td>}
          {adminOutOfSyncColumn && (
            <td
              data-testid="VehicleTable-adminOutOfSyncColumn"
              id="VehicleTable-adminOutOfSyncColumn"
            />
          )}
          {outOfSyncColumn && <td />}

          <td className={styles.actionBtns}>
            {canAddYear && (
              <IconTextButton
                icon="plus"
                text="Add Model Year"
                className={styles.addModelYearCta}
                onClick={() => setaddModel(true)}
              />
            )}
            <Modal
              className={styles.addModelYearModal}
              open={addModel}
              onClose={() => setaddModel(false)}
            >
              <AddModelYearModal
                close={() => setaddModel(false)}
                seriesName={series}
                seriesId={seriesId}
                modelYears={modelYears}
                onAddItem={onAddItem}
                onDuplicateItem={onDuplicateItem}
              />
            </Modal>
            {canRemoveYear && (
              <IconTextButton
                icon="remove"
                text="Remove from Dashboard"
                className={styles.removeCta}
                onClick={() => setRemoveSeries(true)}
              />
            )}
            <Modal open={removeSeries} onClose={() => setRemoveSeries(false)}>
              <RemoveSeriesModal
                close={() => setRemoveSeries(false)}
                seriesName={series}
                onSubmit={() => onRemoveSeries(seriesId)}
              />
            </Modal>
          </td>
        </tr>
      </thead>

      <tbody className={styles.body}>{children}</tbody>
    </table>
  );
};
export default VehicleTable;
