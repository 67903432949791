import { StyleMap } from '../types';

const styleMap: StyleMap = {
  LINK: {
    tag: 'a',
  },
  BOLD: {
    tag: 'strong',
  },
  ITALIC: {
    tag: 'em',
  },
  UNDERLINE: {
    tag: 'u',
  },
};

export default styleMap;
