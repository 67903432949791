import React, { useCallback } from 'react';
import { Upload } from '../../..';
import styles from './VehicleTableRow.module.scss';

interface UploadColumnProps {
  year: number;
  seriesId: string;
  englishVersion?: number;
  onRowClick?: (seriesId: string, year: number) => void;
  onFileUpload?: (file?: File, resultJSON?: object, seriesId?: string, year?: number) => void;
}

const UploadColumn = ({
  year,
  seriesId,
  englishVersion = 0,
  onRowClick = () => undefined,
  onFileUpload,
}: UploadColumnProps) => {
  const onClick = useCallback(() => {
    if (onFileUpload) return undefined;
    return onRowClick(seriesId, year);
  }, [onRowClick, onFileUpload, seriesId, year]);

  return (
    <td role="presentation" className={styles.tableText} onClick={onClick} onKeyPress={() => {}}>
      {onFileUpload && (
        <Upload
          onUpload={onFileUpload}
          uploadLabel="Upload"
          seriesId={seriesId}
          year={year}
          id={`file-input${year}${englishVersion}${seriesId}`}
        />
      )}
    </td>
  );
};

export default React.memo(UploadColumn);
