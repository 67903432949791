import React, { SVGProps } from 'react';

const ReportsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5C18.2762 5 18.5263 5.11198 18.7072 5.29303C18.8881 5.47398 19 5.72393 19 6V8.41421L21.5858 11H24C24.5523 11 25 11.4477 25 12V24C25 25.6569 23.6569 27 22 27H10C8.34315 27 7 25.6569 7 24V8C7 6.34315 8.34315 5 10 5H18ZM10 7H17L23 13V24C23 24.5523 22.5523 25 22 25H10C9.44772 25 9 24.5523 9 24V8C9 7.44772 9.44772 7 10 7ZM12 16H20C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18H12C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16ZM20 20H12C11.4477 20 11 20.4477 11 21C11 21.5523 11.4477 22 12 22H20C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20ZM12 12H14C14.5523 12 15 12.4477 15 13C15 13.5523 14.5523 14 14 14H12C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H10C8.34315 5 7 6.34315 7 8V24C7 25.6569 8.34315 27 10 27H22C23.6569 27 25 25.6569 25 24V12C25 11.7348 24.8946 11.4804 24.7071 11.2929L18.7071 5.29289ZM10 7H17.5858L23 12.4142V24C23 24.5523 22.5523 25 22 25H10C9.44772 25 9 24.5523 9 24V8C9 7.44772 9.44772 7 10 7Z"
        fill="white"
      />
      <path
        d="M19 6C19 5.44772 18.5523 5 18 5C17.4477 5 17 5.44772 17 6V12C17 12.5523 17.4477 13 18 13H24C24.5523 13 25 12.5523 25 12C25 11.4477 24.5523 11 24 11H19V6Z"
        fill="white"
      />
      <path
        d="M20 16H12C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18H20C20.5523 18 21 17.5523 21 17C21 16.4477 20.5523 16 20 16Z"
        fill="white"
      />
      <path
        d="M20 20H12C11.4477 20 11 20.4477 11 21C11 21.5523 11.4477 22 12 22H20C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20Z"
        fill="white"
      />
      <path
        d="M14 12H12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14H14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12Z"
        fill="white"
      />
    </svg>
  );
};

export default ReportsIcon;
