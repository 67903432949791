import React from 'react';
import styles from './filterTab.module.scss';
import FilterTabButton from './FilterTabButton';

interface FilterTabsProps {
  tabs: string[];
  selectedTab: string;
  onSelected: (tab: string) => void;
}

const FilterTabs = ({ tabs, selectedTab, onSelected }: FilterTabsProps) => {
  return (
    <section className={styles.filterTabs}>
      {tabs.map(tab => (
        <FilterTabButton key={tab} isActive={selectedTab === tab} onClick={() => onSelected(tab)}>
          {tab}
        </FilterTabButton>
      ))}
    </section>
  );
};

export default FilterTabs;
