import cx from 'clsx';
import React from 'react';
import { useComponentVisible } from '../../hooks';
import onEnterPress from '../../utils/events/onEnterPress/onEnterPress';
import Input from '../Input/Input';
import TextArea from '../TextArea/TextArea';
import TokenizedTextArea from '../TokenizedTextArea/TokenizedTextArea';
import styles from './CenteredTextArea.module.scss';
import { CenteredTextAreaProps } from './CenteredTextAreaProps';

const CenteredTextArea: React.FC<CenteredTextAreaProps> = ({
  size,
  value,
  onBlurCallback = () => {},
  allowEmpty,
  displayClassName,
  textAreaClassName,
  containerClassName,
  disabled,
  useInput = false,
  keepInputShown = false,
  displayValue = '',
  placeholder,
  allowTokens,
  required,
  tokens,
  maxLength,
  isInvalid = false,
  validate,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const onClick = () => setIsComponentVisible(true);

  if (allowTokens) {
    return (
      <TokenizedTextArea
        size={size}
        value={value ? String(value) : ''}
        className={containerClassName}
        onBlurCallback={onBlurCallback}
        allowEmpty={allowEmpty}
        displayClassName={displayClassName}
        textAreaClassName={textAreaClassName}
        disabled={disabled}
        singleLine={useInput}
        keepInputShown={keepInputShown}
        placeholder={placeholder}
        tokens={tokens}
        displayValue={displayValue}
      />
    );
  }

  const handleOnBlur = (text: string) => {
    if (allowEmpty || text !== '') {
      onBlurCallback(text);
    }
    setIsComponentVisible(false);
  };

  return (
    <div
      onFocus={onClick}
      onClick={onClick}
      tabIndex={0}
      className={cx(
        styles.root,
        disabled && styles.disabled,
        styles[`root-${size}`],
        containerClassName
      )}
      role="textbox"
      onKeyPress={e => onEnterPress(e, onClick)}
    >
      {(disabled || (!isComponentVisible && value && !keepInputShown)) && (
        <div className={cx(styles.display, displayClassName)}>{displayValue || value || ' '}</div>
      )}
      {!disabled && !useInput && (isComponentVisible || !value || keepInputShown) && (
        <TextArea
          required={required}
          autoFocus={!allowEmpty || isComponentVisible}
          placeholder={placeholder}
          disabled={disabled}
          ref={ref}
          defaultValue={value}
          onBlur={e => handleOnBlur(e.currentTarget.value)}
          className={cx(styles.editor, textAreaClassName)}
        />
      )}
      {!disabled && useInput && (isComponentVisible || !value || keepInputShown) && (
        <Input
          required={required}
          autoFocus={!allowEmpty || isComponentVisible}
          placeholder={placeholder}
          disabled={disabled}
          ref={ref}
          defaultValue={value}
          onBlur={e => handleOnBlur(e.currentTarget.value)}
          className={cx(styles.inputEditor, textAreaClassName)}
          maxLength={maxLength}
          isInvalid={isInvalid}
          validate={validate}
        />
      )}
    </div>
  );
};

export default CenteredTextArea;
