import cx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';
import styles from './filterTab.module.scss';

interface FilterTabButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean;
}

const FilterTabButton = ({ isActive, ...restProps }: FilterTabButtonProps) => {
  return (
    <button
      {...restProps}
      className={cx(styles.filterTabButton, { [styles.active]: isActive })}
      type="button"
    />
  );
};

export default FilterTabButton;
