import React, { SVGProps } from 'react';

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.325 16.275C15.1417 16.0583 15.05 15.8127 15.05 15.538C15.05 15.2633 15.1417 15.034 15.325 14.85L17.175 13H10C9.71667 13 9.479 12.904 9.287 12.712C9.095 12.52 8.99934 12.2827 9 12C9 11.7167 9.096 11.479 9.288 11.287C9.48 11.095 9.71734 10.9993 10 11H17.175L15.325 9.15C15.125 8.95 15.025 8.71234 15.025 8.437C15.025 8.16167 15.125 7.92434 15.325 7.725C15.5083 7.525 15.7377 7.425 16.013 7.425C16.2883 7.425 16.5173 7.51667 16.7 7.7L20.3 11.3C20.4 11.4 20.471 11.5083 20.513 11.625C20.555 11.7417 20.5757 11.8667 20.575 12C20.575 12.1333 20.5543 12.2583 20.513 12.375C20.4717 12.4917 20.4007 12.6 20.3 12.7L16.7 16.3C16.4833 16.5167 16.2457 16.6127 15.987 16.588C15.7283 16.5633 15.5077 16.459 15.325 16.275ZM5 21C4.45 21 3.979 20.804 3.587 20.412C3.195 20.02 2.99934 19.5493 3 19V5C3 4.45 3.196 3.979 3.588 3.587C3.98 3.195 4.45067 2.99934 5 3H11C11.2833 3 11.521 3.096 11.713 3.288C11.905 3.48 12.0007 3.71733 12 4C12 4.28333 11.904 4.521 11.712 4.713C11.52 4.905 11.2827 5.00067 11 5H5V19H11C11.2833 19 11.521 19.096 11.713 19.288C11.905 19.48 12.0007 19.7173 12 20C12 20.2833 11.904 20.521 11.712 20.713C11.52 20.905 11.2827 21.0007 11 21H5Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoutIcon;
