import React from 'react';
import { ImageItem } from '../../../models/ImageItem';
import { IconTextButton } from '../../IconTextButton';
import Popover from '../../Popover';
import ImagePopoverContent from '../ImagePopoverContent/ImagePopverContent';

type Props = {
  images: ImageItem[];
  readOnly?: boolean;
  textButtonClassName?: string;
  popoverClass?: string;
  align?: 'right' | 'left';
  defaultOpen?: boolean;
  icon?: 'circle' | 'purpleCircle';
  iconText?: string;
  tabIndex?: number;
  toggleElement?: JSX.Element;
  popoverElement?: JSX.Element;
  onClickAddImage?: () => void;
  onDeleteImage?: () => void;
  onClickEditImage?: () => void;
  closePopover?: () => void;
  onClose?: () => void;
  updateHeroImage?: (newHeroImage: string) => void;
  heroImage?: string;
};

const ImagePopover: React.FC<Props> = ({
  onClickAddImage,
  onClickEditImage,
  onDeleteImage,
  updateHeroImage,
  popoverClass,
  popoverElement,
  images,
  readOnly,
  textButtonClassName,
  align = 'right',
  defaultOpen = false,
  iconText = 'Image',
  tabIndex,
  toggleElement,
  heroImage,
}) => {
  if (readOnly && !images.length) {
    return <></>;
  }

  if (!images.length) {
    return (
      <IconTextButton
        onClick={onClickAddImage}
        tabIndex={tabIndex}
        className={textButtonClassName}
        smallIcon
        icon="plus"
        text={images.length ? `${iconText}(${images.length})` : iconText}
      />
    );
  }
  return (
    <Popover
      popoverClass={popoverClass}
      align={align}
      defaultOpen={defaultOpen}
      toggleElement={
        toggleElement || (
          <IconTextButton
            tabIndex={tabIndex}
            className={textButtonClassName}
            smallIcon
            icon={images.length ? 'circle' : 'plus'}
            text={images.length ? `${iconText}(${images.length})` : iconText}
          />
        )
      }
      popoverElement={
        popoverElement || (
          <ImagePopoverContent
            readOnly={readOnly}
            onClickEditImage={onClickEditImage}
            onDeleteImage={onDeleteImage}
            images={images}
            heroImage={heroImage}
            updateHeroImage={updateHeroImage}
          />
        )
      }
    />
  );
};

export default ImagePopover;
