import React, { useState } from 'react';
import { Input, Button } from '..';

interface DropdownEditAddProps {
  onClick: (value: string) => void;
}

const DropdownEditAdd = ({ onClick }: DropdownEditAddProps) => {
  const [text, setText] = useState('');
  const handleOnClick = () => {
    if (text.trim()) {
      onClick(text.trim());
    }
  };

  return (
    <>
      <Input
        value={text}
        autoFocus
        onChange={e => setText(e.currentTarget.value)}
        onKeyUp={e => {
          if (e.keyCode === 13) {
            handleOnClick();
          }
        }}
      />
      <Button variant="primary" onClick={() => handleOnClick()}>
        {' '}
        Add
      </Button>
    </>
  );
};

export default DropdownEditAdd;
