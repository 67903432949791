import cx from 'clsx';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ResizeDetector from 'react-resize-detector';
import styles from './SideMenu.module.scss';
import SideMenuIcon from './SideMenuIcon';

type SideMenuProps = {
  mainContent: ReactNode;
  onToggleCollapse?: (width: number, windowWidth: number) => void;
  onLogout?: () => void;
  expandedView?: React.ReactNode;
  collapsedView?: React.ReactNode;
  collapsed?: string | null;
};

const SideMenu = ({
  mainContent,
  onToggleCollapse,
  onLogout,
  collapsedView,
  expandedView,
  collapsed: collapsedVal,
}: SideMenuProps) => {
  const ref = useRef<HTMLElement>(null);
  const isCollapsed = localStorage.getItem('sidemenu.collapsed') === 'true';
  const [collapsed, setCollapsed] = useState<boolean>(
    collapsedVal === undefined ? isCollapsed : collapsedVal === 'true'
  );

  useEffect(() => {
    localStorage.setItem('sidemenu.collapsed', collapsed ? 'true' : '');
  }, [collapsed]);

  const handleOnCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleOnResize = (width: number) => {
    if (onToggleCollapse) {
      onToggleCollapse(width, window.innerWidth);
    }
  };

  return (
    <section
      data-collapsed={collapsed}
      className={cx({
        [styles.navContainer]: true,
        [styles.collapsed]: collapsed,
      })}
    >
      <nav
        className={cx({
          [styles.sideMenu]: true,
          [styles.collapsed]: collapsed,
        })}
      >
        {collapsed ? (
          <section>
            <h1 className={styles.titleCollapsed}>V</h1>
            <section className={cx(styles.listContainer, styles.collapsed)}>
              {collapsedView}
            </section>
          </section>
        ) : (
          <section>
            <h1 className={styles.title}>VAPI</h1>
            <section className={styles.listContainer}>{expandedView}</section>
          </section>
        )}
        <footer>
          <div className={cx(styles.footerContainer, collapsed && styles.collapsed)}>
            <div>
              <SideMenuIcon title="Logout" icon="logout" onClick={onLogout}>
                {!collapsed && 'Logout'}
              </SideMenuIcon>
            </div>
          </div>
          <section className={cx(styles.collapseToggle, collapsed && styles.collapsed)}>
            <SideMenuIcon
              icon={collapsed ? 'expandNavArrow' : 'collapseNavArrow'}
              onClick={handleOnCollapsed}
            />
          </section>
        </footer>
      </nav>
      <main ref={ref} className={styles.mainContent}>
        {mainContent}
        <ResizeDetector
          handleWidth
          onResize={width => handleOnResize(width)}
          targetDomEl={ref === null ? undefined : (ref.current as HTMLElement)}
        />
      </main>
    </section>
  );
};

export default SideMenu;
