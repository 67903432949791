import cx from 'clsx';
import React, { useState } from 'react';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import styles from './TextArea.module.scss';

type TextAreaTheme = 'formControl';

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  onDebounce?: () => void;
  onBlurValue?: (value: string) => void;
  noResize?: boolean;
  theme?: TextAreaTheme;
  required?: boolean;
}

const TextArea = React.forwardRef(
  (
    {
      onChange,
      onDebounce,
      onBlur,
      onBlurValue,
      noResize,
      theme,
      required,
      defaultValue,
      value,
      ...rest
    }: TextAreaProps,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    const { debounce } = useDebounce();
    const [hasError, setHasError] = useState(required && !value && !defaultValue);

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (required) {
        setHasError(!event.currentTarget.value);
      }
      if (onChange) {
        onChange(event);
      }
      if (onDebounce) {
        debounce(() => {
          onDebounce();
        });
      }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      if (onBlur) {
        onBlur(event);
      }

      if (onBlurValue) {
        onBlurValue(event.currentTarget.value.trim());
      }
    };

    const getCssClass = () => {
      return cx(
        noResize && styles.noResize,
        hasError && styles.error,
        theme === 'formControl' && styles.formControl
      );
    };

    return (
      <textarea
        ref={ref}
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        className={getCssClass()}
        defaultValue={defaultValue}
        {...rest}
      />
    );
  }
);

export default TextArea;
