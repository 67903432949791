import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent } from 'react';
import styles from './upload.module.scss';
import { UploadProps } from './UploadProps';

const Upload = ({ onUpload, uploadLabel, id, seriesId, year }: UploadProps) => {
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    let resultJSON = {};
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      fileReader.readAsText(event.target.files[0], 'UTF-8');
      fileReader.onload = e => {
        if (e.target && e.target.result && typeof e.target.result === 'string') {
          resultJSON = JSON.parse(e.target.result);
        }
        return onUpload(file, resultJSON, seriesId, year);
      };
      onUpload(file);
      event.target.value = '';
    }
  };

  return (
    <div>
      <label htmlFor={id}>
        {uploadLabel}
        <FontAwesomeIcon icon={faArrowUp} />
      </label>
      <input
        type="file"
        name="upload"
        id={id}
        onChange={changeHandler}
        className={styles.uploadInput}
      />
    </div>
  );
};

export default Upload;
