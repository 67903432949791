import { faCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { useMemo } from 'react';
import DateComponent from '../DateComponent/DateComponent';
import styles from './statusComponent.module.scss';

export enum Icon {
  CIRCLE = 'faCircle',
  EXCLAMATION_TRIANGLE = 'faExclamationTriangle',
}

export enum Status {
  PUBLISHED = 'Published',
  CERTIFIED = 'Certified',
  DRAFT = 'Draft',
  SUBMITTED = 'Submitted',
  OUT_OF_SYNC = 'Out of Sync',
}

interface StatusComponentProps {
  status: Status;
  statusText?: string;
  date?: string;
  dateFormat?: string;
  className?: string;
  statusIcon?: Icon;
}

const StatusComponent = ({
  dateFormat = 'MM/DD/YY',
  date,
  status,
  statusText,
  statusIcon,
  className,
}: StatusComponentProps) => {
  const icon = useMemo(() => {
    switch (statusIcon) {
      case Icon.CIRCLE:
        return faCircle;
      case Icon.EXCLAMATION_TRIANGLE:
        return faExclamationTriangle;
      default:
        return undefined;
    }
  }, [statusIcon]);

  const iconStyle = useMemo(() => {
    switch (status) {
      case Status.PUBLISHED:
        return styles.published;
      case Status.CERTIFIED:
        return styles.certified;
      case Status.DRAFT:
        return styles.draft;
      case Status.SUBMITTED:
        return styles.submitted;
      case Status.OUT_OF_SYNC:
        return styles.outOfSync;
      default:
        return undefined;
    }
  }, [status]);

  return (
    <div className={cx(styles.container, className)}>
      {icon && <FontAwesomeIcon className={iconStyle} icon={icon} />}
      <span className={styles.status}>{statusText ?? status}</span>
      {date && (
        <span className={styles.date}>
          <DateComponent format={dateFormat}>{date}</DateComponent>
        </span>
      )}
    </div>
  );
};

export default StatusComponent;
