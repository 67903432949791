import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import cx from 'clsx';
import styles from './Accordion.module.scss';
import { AccordionHeaderProps } from './AccordionProps';

const AccordionHeader = ({ onToggle, expand, children, className }: AccordionHeaderProps) => {
  return (
    <header className={cx(styles.accordionHeader, className)}>
      <label htmlFor="header">
        {!expand && (
          <FontAwesomeIcon
            className={styles.caret}
            icon={faAngleDown}
            onClick={() => onToggle(!expand)}
          />
        )}
        {expand && (
          <FontAwesomeIcon
            className={styles.caret}
            icon={faAngleUp}
            onClick={() => onToggle(!expand)}
          />
        )}
        <span className={styles.childrenSection}>{children}</span>
      </label>
    </header>
  );
};

export default AccordionHeader;
