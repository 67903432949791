import cx from 'clsx';
import React, { ReactNode } from 'react';
import useComponentVisible from '../../../hooks/useComponentVisible/useComponentVisible';
import addWBR from '../../../utils/functions/addWBR';
import { Dropdown } from '../../Dropdown';
import DropdownButton from '../../Dropdown/DropdownButton';
import { DropdownEdit } from '../../DropdownEdit';
import styles from './DropdownEditorCell.module.scss';

type Props = {
  onAdd: (idx: string) => void;
  value: string;
  disabled?: boolean;
  className?: string;
  addBtnText?: string;
  tabIndex?: number;
  error?: boolean;
  renderList: (onClose: () => void) => ReactNode;
};

const DropdownEditorCell: React.FC<Props> = ({
  onAdd,
  value,
  disabled,
  className,
  addBtnText = 'Category',
  tabIndex,
  error,
  renderList,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <td className={cx(styles.root, className)}>
      <Dropdown>
        <DropdownButton
          error={error}
          tabIndex={tabIndex}
          disabled={disabled}
          className={styles.tableDropdown}
          onClick={() => setIsComponentVisible(true)}
        >
          {addWBR(value)}
        </DropdownButton>
        <DropdownEdit
          ref={ref}
          open={isComponentVisible}
          addBtnText={addBtnText}
          onAdd={onAdd}
          renderList={() => renderList(() => setIsComponentVisible(false))}
        />
      </Dropdown>
    </td>
  );
};

export default DropdownEditorCell;
