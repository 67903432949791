import React, { useEffect, useRef } from 'react';
import TableRowBase, { TableRowBaseProps } from './TableRowBase';

export interface TableRowProps extends TableRowBaseProps {
  onFillRowHeightChange?(height: number): void;
  rowHeight?: string | number;
}

export const TableRow: React.FC<TableRowProps> = ({
  onFillRowHeightChange,
  rowHeight,
  ...rest
}) => {
  const ref = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (onFillRowHeightChange) {
      setTimeout(() => {
        if (ref && ref.current) {
          onFillRowHeightChange(ref.current.offsetHeight);
        }
      }, 100);
    }
  }, [onFillRowHeightChange]);

  return (
    <TableRowBase
      innerRef={ref}
      style={{
        height: `${rowHeight}px`,
      }}
      {...rest}
    />
  );
};
