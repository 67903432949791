import React from 'react';
import { ActionBar, ActionBarSection } from '../ActionBar';
import Header from '../Header/Header';
import IconTextButton from '../IconTextButton/IconTextButton';
import SearchInput from '../SearchInput/SearchInput';
import styles from './components/VehicleTableRow/VehicleTableRow.module.scss';

interface VehicleDashboardProps {
  teamTitle: string;
  onSearch: (value: string) => void;
  onAddVehiclesClick: () => void;
  children?: React.ReactNode;
}

const VehicleDashboard = ({
  teamTitle,
  onSearch,
  onAddVehiclesClick,
  children,
}: VehicleDashboardProps) => {
  return (
    <>
      <Header moduleTitle={teamTitle} moduleSubTitle="Dashboard" />
      <ActionBar>
        <ActionBarSection>
          <SearchInput onSearch={onSearch} />
          <IconTextButton
            icon="plus"
            text="Add Vehicle(s) to Dashboard"
            className={styles.addVehicleCta}
            smallIcon
            onClick={onAddVehiclesClick}
          />
        </ActionBarSection>
      </ActionBar>
      {children}
    </>
  );
};

export default VehicleDashboard;
