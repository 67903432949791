import cn from 'clsx';
import React from 'react';
import styles from './Tabs.module.scss';

// https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
type Props = {
  tabs: string[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
};

const Tabs: React.FC<Props> = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <div className={styles.root}>
      <div className={styles.tabs} role="tablist" aria-label="Entertainment">
        {tabs.map(tab => (
          <button
            key={tab}
            type="button"
            className={cn(styles.tab, {
              [styles.active]: tab === selectedTab,
            })}
            role="tab"
            aria-selected={tab === selectedTab}
            aria-controls={`${tab}-tabpanel`}
            id={tab}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
