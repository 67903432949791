import createLinkPlugin, { AnchorPlugin } from '@draft-js-plugins/anchor';
import Editor, { EditorPlugin } from '@draft-js-plugins/editor';
import createInlineToolbarPlugin, { InlineToolBarPlugin } from '@draft-js-plugins/inline-toolbar';
import { EditorState } from 'draft-js';
import anchorStyles from './anchor/anchor.module.scss';
import buttonStyles from './buttons/button.module.scss';
import createMentionPlugin from './mentions';
import mentionStyles from './mentions/mentions.module.scss';
import { MentionSuggestionsPubProps } from './mentions/MentionSuggestions/MentionSuggestions';
import toolbarStyles from './toolbar/toolbar.module.scss';

// Actions
export const INIT = 'init';
export const SET_EDITOR = 'set_editor';
export const SET_EDITOR_STATE = 'set_editor_state';
export const SET_SUGGESTIONS = 'set_suggestions';
export const SET_MENTION_OPEN = 'set_mention_open';

export type SuggestionType = {
  name: string;
};

type RTEditorState = {
  isLoaded: boolean;
  isMentionOpen: boolean;
  editor: Editor | null;
  editorState: EditorState;
  allSuggestions: SuggestionType[];
  suggestions: SuggestionType[];
  linkPlugin: AnchorPlugin;
  inlineToolbarPlugin: InlineToolBarPlugin;
  mentionPlugin: EditorPlugin & {
    MentionSuggestions: React.ComponentType<MentionSuggestionsPubProps>;
  };
  plugins: any[];
};

type RTEditorAction =
  | {
      type: typeof INIT;
      payload: { editorState: EditorState; suggestions?: SuggestionType[] };
    }
  | { type: typeof SET_EDITOR; payload: { editor: Editor | null } }
  | {
      type: typeof SET_EDITOR_STATE;
      payload: { editorState: EditorState };
    }
  | { type: typeof SET_SUGGESTIONS; payload: { suggestions: SuggestionType[] } }
  | { type: typeof SET_MENTION_OPEN; payload: { isMentionOpen: boolean } };

/**
 * getInitialState
 */
export const getInitialState = (): RTEditorState => {
  const linkPlugin = createLinkPlugin({
    theme: {
      link: anchorStyles.link,
      input: anchorStyles.input,
      inputInvalid: anchorStyles.inputInvalid,
    },
  });

  const defaultToolbarTheme = {
    toolbarStyles,
    buttonStyles,
  };
  const inlineToolbarPlugin = createInlineToolbarPlugin({ theme: defaultToolbarTheme });

  const mentionPlugin = createMentionPlugin({
    theme: mentionStyles,
    mentionTrigger: '[',
  });

  const plugins = [linkPlugin, inlineToolbarPlugin, mentionPlugin];

  return {
    isLoaded: false,
    isMentionOpen: false,
    editor: null,
    editorState: EditorState.createEmpty(),
    allSuggestions: [] as SuggestionType[],
    suggestions: [] as SuggestionType[],
    linkPlugin,
    inlineToolbarPlugin,
    mentionPlugin,
    plugins,
  };
};

export const reducer = (state: RTEditorState, action: RTEditorAction): RTEditorState => {
  switch (action.type) {
    case INIT: {
      const suggestions = action.payload.suggestions || ([] as SuggestionType[]);
      return {
        ...state,
        editorState: action.payload.editorState,
        allSuggestions: suggestions,
        suggestions,
        isLoaded: true,
      };
    }

    case SET_EDITOR:
    case SET_EDITOR_STATE:
    case SET_MENTION_OPEN:
    case SET_SUGGESTIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
