/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { Button } from '../Button';
import NumberFormat from '../NumberFormat';
import Popover from '../Popover';
import ExtraCostContent from './ExtraCostContent';
import styles from './ExtraCostPopover.module.scss';

interface CostProps {
  msrp: string;
  readOnly?: boolean;
  handleUpdateItem?: (msrp: string) => void;
  popoverClass?: string;
  defaultOpen?: boolean;
  title?: string;
  tabIndex?: number;
}

const ExtraCostPopover = ({
  msrp,
  readOnly,
  popoverClass,
  handleUpdateItem,
  defaultOpen = false,
  title = '',
  tabIndex
}: CostProps) => {

  const [ctaStyling, setCtaStyling] = useState(false);

  return (
    <>
      {!readOnly && (
        <Popover
          popoverClass={popoverClass}
          defaultOpen={defaultOpen}
          toggleElement={
            msrp && Number(msrp) > 0 && msrp !== '' ? (
              <div data-for="msrp" className={styles.msrpLabel}>
                <NumberFormat value={msrp} />
              </div>
            ) : (
              <Button tabIndex={tabIndex} variant="extraCost" toggled={ctaStyling} disabled={readOnly}>
                $$$
              </Button>
            )
          }
          popoverElement={
            <ExtraCostContent
              msrp={msrp}
              onUpdate={msrp1 => {
                if (typeof handleUpdateItem === 'function') { handleUpdateItem(msrp1); }        
              }}
              onCostChange={cost => {
                setCtaStyling(Number(cost) > 0);
              }}
              title={title}
            />
          }
        />
      )}
      {readOnly && (
        <div data-for="msrp" className={styles.msrpLabel}>
          <NumberFormat value={msrp} />
        </div>
      )}
    </>
  );
};

export default ExtraCostPopover;
