import cx from 'clsx';
import React from 'react';
import { ImagePopover } from '..';
import { Checkbox } from '../Checkbox';
import ExtraCostPopover from '../ExtraCostPopover';
import { IconTextButton } from '../IconTextButton';
import NotesPopover from '../NotesPopover';
import { TableCell } from '../Table';
import HighlightFeatureAction from './components/HighlightFeatureAction';
import styles from './FlagsCell.module.scss';
import { ImageItem } from '../../models/ImageItem';

export type FlagCellProps = {
  displayInProgressCheckBox?: boolean;
  toggleInProgress?: () => void;
  toggleHighlighted?: () => void;
  handleOnAddGradeId?: (gradeId: string) => void;
  handleOnEditGradeId?: (oldVal: string, newVal: string) => void;
  displayHighlighted?: boolean;
  displayImagePopover?: boolean;
  onNotesChange?: (notes: string) => void;
  highlighted: boolean;
  renderMsrpPopover?: () => JSX.Element;
  inProgress: boolean | null | undefined;
  notes: string | null | undefined;
  images?: ImageItem[] | null;
  index: number;
  tabIndex?: number;
  popoverElement?: JSX.Element;
  disabled?: boolean;
  onExtraCostChange?: (msrp: string) => void;
  onGradeIdExtraCostChange?: (gradeMsrp: any) => void;
  rejectNotes?: string;
  msrp?: string;
  heroImage?: string;
  msrpPopover?: boolean;
  inProgressText?: string;
  updateHeroImage?: (newHeroImage: string) => void;
  onClickAddImage?: () => void;
  onClickEditImage?: () => void;
  onDeleteImage?: () => void;
} & React.HTMLAttributes<HTMLTableCellElement>;

const FlagsCell: React.FC<FlagCellProps> = ({
  notes = '',
  images = [] as ImageItem[],
  index,
  tabIndex,
  className,
  popoverElement,
  displayImagePopover = false,
  inProgress = false,
  highlighted,
  renderMsrpPopover,
  updateHeroImage = () => {},
  onNotesChange = () => {},
  onClickAddImage = () => {},
  onClickEditImage = () => {},
  onDeleteImage = () => {},
  toggleInProgress = () => {},
  toggleHighlighted = () => {},
  heroImage = '',
  displayHighlighted = true,
  displayInProgressCheckBox = true,
  disabled,
  onExtraCostChange = () => {},
  rejectNotes,
  msrp,
  inProgressText = 'In Progress',
}) => {
  return (
    <TableCell colType="flags" className={cx(styles.flagsCellWrapper, className)}>
      <div className={styles.flagsCell}>
        {!renderMsrpPopover && (msrp || msrp === '') && (
          <ExtraCostPopover
            tabIndex={tabIndex}
            msrp={msrp}
            handleUpdateItem={onExtraCostChange}
            readOnly={disabled}
            popoverClass={styles.msrpPopoverClass}
            title=""
          />
        )}
        {renderMsrpPopover && renderMsrpPopover()}
        <NotesPopover
          notes={notes ?? ''}
          readOnly={disabled}
          handleUpdateItem={onNotesChange}
          textButtonClassName={styles.notes}
          popoverClass={styles.notesPopoverClass}
        />
        {displayImagePopover && (
          <ImagePopover
            heroImage={heroImage}
            updateHeroImage={updateHeroImage}
            onClickEditImage={onClickEditImage}
            onClickAddImage={onClickAddImage}
            onDeleteImage={onDeleteImage}
            images={images || []}
            readOnly={disabled}
            popoverElement={popoverElement}
            textButtonClassName={styles.notes}
            popoverClass={styles.notesPopoverClass}
          />
        )}
        {displayInProgressCheckBox && !disabled && (
          <Checkbox
            id={`inprogress-${index}`}
            tabIndex={tabIndex}
            className={styles.checkbox}
            checked={inProgress ?? false}
            onChange={toggleInProgress}
          >
            <span className={styles.checkboxLabel}>{inProgressText}</span>
          </Checkbox>
        )}
        {disabled && inProgress && (
          <IconTextButton
            smallIcon
            icon="redCircle"
            text={inProgressText}
            className={styles.notes}
          />
        )}
        {rejectNotes && (
          <NotesPopover
            notes={rejectNotes}
            icon="purpleCircle"
            iconText="Review"
            readOnly
            handleUpdateItem={onNotesChange}
            textButtonClassName={styles.notes}
            popoverClass={styles.notesPopoverClass}
          />
        )}
        {heroImage && <img className={styles.flageCellImage} src={heroImage} alt="" />}
      </div>
      {displayHighlighted && highlighted && (
        <HighlightFeatureAction
          tabIndex={tabIndex}
          disabled={disabled}
          highlighted={highlighted}
          onClick={toggleHighlighted}
        />
      )}
    </TableCell>
  );
};

export default FlagsCell;
