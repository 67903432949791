import React, { useState } from 'react';
import Button from '../../../Button/Button';
import { Dropdown } from '../../../Dropdown';
import DropdownList from '../../../DropdownList';
import IconTextButton from '../../../IconTextButton/IconTextButton';
import Input from '../../../Input/Input';
import { ModalFooter } from '../../../Modal';
import ModalBody from '../../../Modal/ModalBody';
import ModalHeader from '../../../Modal/ModalHeader';
import styles from './AddModelYearModal.module.scss';

interface AddModelYearModalProps {
  close: () => void;
  modelYears: string[];
  seriesName?: string;
  seriesId: string;
  onAddItem: (seriesId: string, modelYear: number) => void;
  onDuplicateItem: (seriesId: string, fromModelYear: number, toModelYear: number) => void;
}

const AddModelYearModal = ({
  close,
  modelYears,
  seriesName,
  seriesId,
  onAddItem,
  onDuplicateItem,
}: AddModelYearModalProps) => {
  const [options, showOptions] = useState(true);
  const [duplicate, setDuplicate] = useState(false);
  const [create, setCreate] = useState(false);
  const [currentModelYear, setCurrentModelYear] = useState('');
  const [duplicateFromModelYear, setDuplicateFromModelYear] = useState(modelYears[0] || '');  
  const cleanedModelYears = [...new Set(modelYears)]

  return (
    <>
      <ModalHeader onClose={close}>
        <span>Add Model to </span>
        <span>{` ${seriesName}`}</span>
      </ModalHeader>
      <ModalBody>
        {options && (
          <>
            <span className={styles.duplicateCta}>
              <IconTextButton
                icon="duplicate"
                text="Duplicate Model Year"
                className={styles.content}
                onClick={() => {
                  setDuplicate(true);
                  setCreate(false);
                  showOptions(false);
                }}
              />
            </span>
            <span className={styles.seperator}>or</span>
            <span className={styles.createCta}>
              <IconTextButton
                icon="plus"
                text="Create New"
                className={styles.content}
                onClick={() => {
                  setDuplicate(false);
                  setCreate(true);
                  showOptions(false);
                }}
              />
            </span>
          </>
        )}

        {duplicate && (
          <>
            <div className={styles.dropdownWrapper}>
              <span className={styles.modelYearLabel}>Duplicate Model Year</span>
              <Dropdown className={styles.modelYearDropdown}>
                <DropdownList
                  list={cleanedModelYears}
                  value={duplicateFromModelYear}
                  onSelect={(item: string) => setDuplicateFromModelYear(item)}
                />
              </Dropdown>
            </div>
          </>
        )}

        {(duplicate || create) && (
          <>
            <div>
              <span className={styles.modelYearLabel}>Year Title</span>
              <Input
                className={styles.yearTitle}
                onChange={e => setCurrentModelYear(e.currentTarget.value)}
              />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {(duplicate || create) && (
          <Button
            variant="transparent"
            onClick={() => {
              setDuplicate(false);
              setCreate(false);
              showOptions(true);
            }}
            className={styles.backCta}
          >
            Back
          </Button>
        )}

        <Button variant="transparent" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (Number(currentModelYear)) {
              close();
              if (duplicate) {
                onDuplicateItem(seriesId, Number(duplicateFromModelYear), Number(currentModelYear));
              } else {
                onAddItem(seriesId, Number(currentModelYear));
              }
            }
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  );
};
export default AddModelYearModal;
