import React, { SVGProps } from 'react';

const ColorFamIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 21H11.01M11 25C9.93913 25 8.92172 24.5786 8.17157 23.8284C7.42143 23.0783 7 22.0609 7 21V9C7 8.46957 7.21071 7.96086 7.58579 7.58579C7.96086 7.21071 8.46957 7 9 7H13C13.5304 7 14.0391 7.21071 14.4142 7.58579C14.7893 7.96086 15 8.46957 15 9V21C15 22.0609 14.5786 23.0783 13.8284 23.8284C13.0783 24.5786 12.0609 25 11 25V25ZM11 25H23C23.5304 25 24.0391 24.7893 24.4142 24.4142C24.7893 24.0391 25 23.5304 25 23V19C25 18.4696 24.7893 17.9609 24.4142 17.5858C24.0391 17.2107 23.5304 17 23 17H20.657L11 25ZM15 11.343L16.657 9.686C17.0321 9.31106 17.5407 9.10043 18.071 9.10043C18.6013 9.10043 19.1099 9.31106 19.485 9.686L22.314 12.515C22.6889 12.8901 22.8996 13.3987 22.8996 13.929C22.8996 14.4593 22.6889 14.9679 22.314 15.343L13.828 23.828L15 11.343Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ColorFamIcon;
