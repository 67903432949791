import cx from 'clsx';
import React, { HTMLAttributes } from 'react';
import styles from './descriptionText.module.scss';

interface DescriptionTextProps extends HTMLAttributes<HTMLElement> {
  size?: 'sm' | 'md' | 'lg';
}
const DescriptionText = ({ size, ...rest }: DescriptionTextProps) => {
  return (
    <div
      className={cx(
        styles.descriptionText,
        size === 'sm' && styles.small,
        size === 'lg' && styles.large
      )}
      {...rest}
    />
  );
};

export default DescriptionText;
