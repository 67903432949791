import cx from 'clsx';
import React, { Ref } from 'react';
import { animated, useTransition } from 'react-spring';
import styles from './Dropdown.module.scss';

interface DropdownContentProps {
  open: boolean;
  menuClass?: string;
  align?: string;
  children?: React.ReactNode;
}

const DropdownContent = React.forwardRef(
  ({ open, menuClass = '', align = '', children = null }: DropdownContentProps, ref: Ref<any>) => {
    const transitions = useTransition(open, null, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 50 },
    });

    const onBlur = () => {};

    return (
      <>
        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <div key={key} className={styles.dropdownContent} onBlur={onBlur}>
                <animated.div
                  style={props}
                  ref={ref}
                  className={cx([
                    styles.dropdownMenu,
                    menuClass,
                    { [styles.alignRight]: align === 'right' },
                  ])}
                >
                  {children}
                </animated.div>
              </div>
            )
        )}
      </>
    );
  }
);

export default DropdownContent;
