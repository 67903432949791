import React, { SVGProps } from 'react';

const HelpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_289_1293"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="22"
        height="22"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 16C5 22.0751 9.86407 27 15.8642 27C21.8643 27 26.7284 22.0751 26.7284 16C26.7284 9.92487 21.8643 5 15.8642 5C9.86407 5 5 9.92487 5 16ZM24.7531 16C24.7531 20.9706 20.7734 25 15.8642 25C10.955 25 6.97531 20.9706 6.97531 16C6.97531 11.0294 10.955 7 15.8642 7C20.7734 7 24.7531 11.0294 24.7531 16Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_289_1293)">
        <rect x="4.01234" y="4" width="23.7037" height="24" fill="white" />
      </g>
      <path
        d="M12.6592 13.1411C12.6592 12.6772 12.8081 12.2085 13.106 11.7349C13.4038 11.2563 13.8384 10.8608 14.4097 10.5483C14.981 10.2358 15.6475 10.0796 16.4092 10.0796C17.1172 10.0796 17.7422 10.2114 18.2842 10.4751C18.8262 10.7339 19.2437 11.0879 19.5366 11.5371C19.8345 11.9863 19.9834 12.4746 19.9834 13.002C19.9834 13.417 19.8979 13.7808 19.7271 14.0933C19.561 14.4058 19.3608 14.6768 19.1265 14.9062C18.897 15.1309 18.4819 15.5117 17.8813 16.0488C17.7153 16.2002 17.5811 16.3345 17.4785 16.4517C17.3809 16.564 17.3076 16.6689 17.2588 16.7666C17.21 16.8594 17.1709 16.9546 17.1416 17.0522C17.1172 17.145 17.0781 17.311 17.0244 17.5503C16.9316 18.0581 16.6411 18.312 16.1528 18.312C15.8989 18.312 15.6841 18.229 15.5083 18.063C15.3374 17.897 15.252 17.6504 15.252 17.3232C15.252 16.9131 15.3154 16.5591 15.4424 16.2612C15.5693 15.9585 15.7378 15.6948 15.9478 15.4702C16.1577 15.2407 16.4409 14.9697 16.7974 14.6572C17.1099 14.3838 17.3345 14.1787 17.4712 14.042C17.6128 13.9004 17.73 13.7441 17.8228 13.5732C17.9204 13.4023 17.9692 13.2168 17.9692 13.0166C17.9692 12.626 17.8228 12.2964 17.5298 12.0278C17.2417 11.7593 16.8682 11.625 16.4092 11.625C15.8721 11.625 15.4766 11.7617 15.2227 12.0352C14.9688 12.3037 14.7539 12.7017 14.5781 13.229C14.4121 13.7808 14.0972 14.0566 13.6333 14.0566C13.3599 14.0566 13.1279 13.9614 12.9375 13.771C12.752 13.5757 12.6592 13.3657 12.6592 13.1411ZM16.2334 21.1685C15.9355 21.1685 15.6743 21.0732 15.4497 20.8828C15.23 20.6875 15.1201 20.4165 15.1201 20.0698C15.1201 19.7622 15.2275 19.5034 15.4424 19.2935C15.6572 19.0835 15.9209 18.9785 16.2334 18.9785C16.541 18.9785 16.7998 19.0835 17.0098 19.2935C17.2197 19.5034 17.3247 19.7622 17.3247 20.0698C17.3247 20.4116 17.2148 20.6802 16.9951 20.8755C16.7754 21.0708 16.5215 21.1685 16.2334 21.1685Z"
        fill="white"
      />
    </svg>
  );
};

export default HelpIcon;
