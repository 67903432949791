import React, { SVGProps } from 'react';

const ChangeLogIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3333 8.33668C17.3333 8.6903 17.1929 9.02944 16.9428 9.27948C16.6928 9.52953 16.3536 9.67001 16 9.67001C15.6464 9.67001 15.3072 9.52953 15.0572 9.27948C14.8071 9.02944 14.6667 8.6903 14.6667 8.33668C14.6667 7.98305 14.8071 7.64391 15.0572 7.39387C15.3072 7.14382 15.6464 7.00334 16 7.00334C16.3536 7.00334 16.6928 7.14382 16.9428 7.39387C17.1929 7.64391 17.3333 7.98305 17.3333 8.33668ZM19.2667 7.67001C19.1137 6.91648 18.7048 6.23903 18.1095 5.75242C17.5142 5.26582 16.7689 5 16 5C15.2311 5 14.4858 5.26582 13.8905 5.75242C13.2952 6.23903 12.8863 6.91648 12.7333 7.67001H9.33333C8.97971 7.67001 8.64057 7.81048 8.39052 8.06053C8.14048 8.31058 8 8.64972 8 9.00334V25.0033C8 25.357 8.14048 25.6961 8.39052 25.9462C8.64057 26.1962 8.97971 26.3367 9.33333 26.3367H22.6667C23.0203 26.3367 23.3594 26.1962 23.6095 25.9462C23.8595 25.6961 24 25.357 24 25.0033V9.00334C24 8.64972 23.8595 8.31058 23.6095 8.06053C23.3594 7.81048 23.0203 7.67001 22.6667 7.67001H19.2667ZM16 11.67H12.6667V9.67001H10V24.3367H22V9.67001H19.3333V11.67H16ZM12 15.3367C12 15.0715 12.1054 14.8171 12.2929 14.6296C12.4804 14.442 12.7348 14.3367 13 14.3367H19C19.2652 14.3367 19.5196 14.442 19.7071 14.6296C19.8946 14.8171 20 15.0715 20 15.3367C20 15.6019 19.8946 15.8562 19.7071 16.0438C19.5196 16.2313 19.2652 16.3367 19 16.3367H13C12.7348 16.3367 12.4804 16.2313 12.2929 16.0438C12.1054 15.8562 12 15.6019 12 15.3367ZM13 17.67C12.7348 17.67 12.4804 17.7754 12.2929 17.9629C12.1054 18.1504 12 18.4048 12 18.67C12 18.9352 12.1054 19.1896 12.2929 19.3771C12.4804 19.5647 12.7348 19.67 13 19.67H19C19.2652 19.67 19.5196 19.5647 19.7071 19.3771C19.8946 19.1896 20 18.9352 20 18.67C20 18.4048 19.8946 18.1504 19.7071 17.9629C19.5196 17.7754 19.2652 17.67 19 17.67H13Z"
        fill="white"
      />
    </svg>
  );
};

export default ChangeLogIcon;
