import { ContentState, convertFromRaw } from 'draft-js';

export const convertToRichTextObject = (value: string) => {
  try {
    if (!Number.isNaN(Number(value))) {
      return {
        text: value,
      };
    }
    const retObj = JSON.parse(value);
    return retObj;
  } catch {
    return {
      text: value,
    };
  }
};

export const areRichTextValuesDifferent = (
  oldValue: string | null | undefined,
  newValue: string | null | undefined
) => {
  const oldVal = convertToRichTextObject(oldValue ?? '');
  const newVal = convertToRichTextObject(newValue ?? '');

  // when both have rich text properties, just compare value strings themselves
  if (oldVal.content && newVal.content) {
    return oldValue !== newValue;

    // if first value has no rich text, but second value has rich text style changes, compare value strings themselves
  }

  if (!oldVal.content && newVal.content) {
    let newValHasStyleChanges = oldVal.text !== newVal.text;
    if (!newValHasStyleChanges) {
      newVal.content.blocks.forEach((block: any) => {
        if (block.inlineStyleRanges.length > 0) {
          newValHasStyleChanges = true;
        }
      });
    }

    return newValHasStyleChanges;

    // otherwise just compare the text
  }

  return oldVal.text !== newVal.text;
};

/**
 * Convert value from service to editor object
 * @param value
 */
export const getContentState = (value: string) => {
  try {
    return convertFromRaw(JSON.parse(value).content);
  } catch {
    // eslint-disable-no-empty
  }

  return ContentState.createFromText(value);
};

export const processRteForChangeLog = (responseString: string) => {
  try {
    const rteObj = JSON.parse(responseString);
    let processedString;
    if (rteObj) {
      const stringbuild = rteObj.content.blocks.map((block: any) => {
        let blockText: string = block.text;
        if (blockText.replace(' ', '')) {
          blockText =
            block.type === 'unordered-list-item' ? `• ${block.text}` || '' : `${block.text}` || '';
        }
        return `${blockText}`;
      });
      processedString = stringbuild.join('\n');
    }

    return processedString || responseString || '';
  } catch (_e) {
    return responseString;
  }
};
