/**
 * ActionBar container used in most screens in the app
 */
import React, { HTMLAttributes } from 'react';
import cx from 'clsx';
import { DropdownButton } from '../Dropdown';
import { DropdownButtonProps } from '../Dropdown/DropdownProps';
import styles from './ActionBar.module.scss';

export const ActionBar = (props: HTMLAttributes<HTMLDivElement>) => {
  return <section {...props} className={styles.actionBar} />;
};

export const ActionBarSection = (props: HTMLAttributes<HTMLDivElement>) => {
  return <section {...props} className={styles.actionBarSection} />;
};

export const ActionBarDivider = () => <div className={styles.actionBarDivider} />;

export const ActionBarFilterButton = (props: DropdownButtonProps) => {
  const { className } = props;
  return <DropdownButton {...props} className={cx(styles.filterButton, className)} />;
};
