import React, { useState } from 'react';
import styles from './ExtraCostPopover.module.scss';
import { Button } from '../Button';
import Input from '../Input/Input';

interface MsrpProps {
  msrp: string;
  onUpdate?: (cost: string) => void;
  closePopover?: () => void;
  onCostChange?: (cost: string) => void;
  title?: string;
}

const ExtraCostContent = (props: MsrpProps) => {
  const {
    msrp,
    closePopover,
    onUpdate,
    onCostChange, // readOnly
    title = "Extra Cost Color"
  } = props;
  const [formMsrp, setFormMsrp] = useState(msrp);

  return (
    <section className={styles.msrpSection}>
      <header className={styles.header}>{title}</header>
      <div>
        <div data-for="msrp" className={styles.label}>
          MSRP
        </div>
        <span className={styles.currencyInput}>
          <Input
            className={styles.input}
            onChange={e => {
              setFormMsrp(e.currentTarget.value);
              if (typeof onCostChange === 'function') { onCostChange(e.currentTarget.value.trim()); }              
            }}
            value={formMsrp}
          />
        </span>
      </div>
      <footer className={styles.footer}>
        <Button
          variant="transparent"
          onClick={() => {            
            if (typeof closePopover === 'function') { closePopover(); }    
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (typeof onUpdate === 'function') { onUpdate(formMsrp.trim()); }
            if (typeof closePopover === 'function') { closePopover(); }
          }}
        >
          Save
        </Button>
      </footer>
    </section>
  );
};

export default ExtraCostContent;
