import React from 'react';
import { Radio, Button } from '../..';
import styles from '../ImagePopover/ImagePopover.module.scss';
import removeFileFomat from '../../../utils/functions/images/removeFileFormat';
import { ImageItem } from '../../..';

interface ImagePopoverContentProps {
  images: ImageItem[];
  readOnly?: boolean;
  onDeleteImage?: () => void;
  onClickEditImage?: () => void;
  closePopover?: () => void;
  updateHeroImage?: (newHeroImage: string) => void;
  heroImage?: string;
}

const ImagePopoverContent = ({
  readOnly,
  onClickEditImage,
  onDeleteImage,
  closePopover,
  updateHeroImage,
  images,
  heroImage,
}: ImagePopoverContentProps) => {
  return (
    <div>
      <header className={styles.existingPopoverHeader}>
        <span>Image</span>
        <span>Hero</span>
      </header>
      <div className={styles.existingPopoverContent}>
        <ul>
          {images.map(image => (
            <li>
              <img alt="images" src={image.image} />
              <span className={styles.imgPtNumber}>{removeFileFomat(image.name)}</span>
              <Radio
                className={styles.imgRadioBtn}
                disabled={readOnly}
                label=""
                name="selectedImages"
                value={image.image}
                selectedValue={heroImage}
                onChange={() => {
                  if (updateHeroImage && !readOnly) {
                    updateHeroImage(image.image);
                  }
                }}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.existingBtnTable}>
        {!readOnly ? (
          <>
            <Button variant="transparent" onClick={onClickEditImage}>
              edit
            </Button>

            <Button variant="primary" onClick={onDeleteImage}>
              delete
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={closePopover}>
            close
          </Button>
        )}
      </div>
    </div>
  );
};

export default ImagePopoverContent;
