import React from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from '../..';

const ImageModal = () => {
  return (
    <>
      <ModalHeader onClose={() => {}}>Image Modal</ModalHeader>
      <ModalHeader onClose={() => {}}>Image</ModalHeader>
      <ModalBody>testing image modal</ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={() => {}}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => {}}>
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};

export default ImageModal;
