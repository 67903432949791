import React, { ReactNode } from 'react';
import { Button } from '../Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';

interface ActionModalProps {
  title: string;
  buttonText: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children?: ReactNode;
}

const ActionModal = ({
  title,
  buttonText,
  open,
  onClose,
  onConfirm,
  children,
}: ActionModalProps) => {
  const handleOnConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleOnConfirm}>
          {buttonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ActionModal;
