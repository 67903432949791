import cx from 'clsx';
import React, { HTMLAttributes } from 'react';
import styles from './filterTab.module.scss';

interface FilterTabSectionProps extends HTMLAttributes<HTMLElement> {
  active: boolean;
}

const FilterTabSection = ({ active, ...rest }: FilterTabSectionProps) => {
  return <div className={cx(styles.filterTabSection, active && styles.active)} {...rest} />;
};

export default FilterTabSection;
