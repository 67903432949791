import React from 'react';
import { Button } from '../../Button';
import { ModalBody, ModalFooter, ModalHeader } from '../../Modal';

interface RemoveVehicleModalProps {
  close: () => void;
  seriesName?: string;
  onSubmit: () => void;
}

const RemoveSeriesModal = ({ close, seriesName, onSubmit }: RemoveVehicleModalProps) => {
  const body = `Are you sure you want to remove "${seriesName}" from your dashboard?`;

  return (
    <>
      <ModalHeader onClose={close}>Remove Vehicle</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onSubmit();
            close();
          }}
        >
          Remove
        </Button>
      </ModalFooter>
    </>
  );
};

export default RemoveSeriesModal;
