import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const SERVER_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A z';
const DEFAULT_DISPLAY_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';

export const convertServerDateToLocalDate = (date: string, format?: string) => {
  return dayjs(date, SERVER_TIME_FORMAT).format(format || DEFAULT_DISPLAY_TIME_FORMAT);
};

interface DateComponentProps {
  format: string;
  className?: string;
  children?: React.ReactNode;
}

const DateComponent = ({ children, className, format }: DateComponentProps) => {
  const [time, setTime] = useState('');

  useEffect(() => {
    try {
      if (children) {
        setTime(convertServerDateToLocalDate(children.toString(), format));
      }
    } catch {
      setTime('');
    }
  }, [children, format]);
  return time ? <time className={className}>{time}</time> : null;
};

export default DateComponent;
