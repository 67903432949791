import cx from 'clsx';
import React from 'react';
import styles from './Button.module.scss';
import { ButtonProps } from './ButtonProps';

const Button = ({ variant, className, toggled, ...rest }: ButtonProps) => {
  return (
    <button
      type="button"
      className={cx([
        className,
        {
          [styles.button]: true,
          [styles.toggled]: toggled,
          [styles.primary]: variant === 'primary',
          [styles.secondary]: variant === 'secondary',
          [styles.outline]: variant === 'outline',
          [styles.transparent]: variant === 'transparent',
          [styles.selection]: variant === 'selection',
          [styles.full]: variant === 'full',
          [styles.extraCost]: variant === 'extraCost',
          [styles.error]: variant === 'error',
        },
      ])}
      {...rest}
    />
  );
};

export default Button;
