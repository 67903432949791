import React, { SVGProps } from 'react';

const DisclaimerIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_289_1289"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width="23"
        height="22"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9406 5H11.7629C11.5009 5 11.2497 5.10536 11.0645 5.29289L5.27683 11.1529C5.0916 11.3404 4.98755 11.5948 4.98755 11.86V20.14C4.98755 20.4052 5.0916 20.6596 5.27683 20.8471L11.0645 26.7071C11.2497 26.8946 11.5009 27 11.7629 27H19.9406C20.2026 27 20.4538 26.8946 20.639 26.7071L26.4267 20.8471C26.6119 20.6596 26.7159 20.4052 26.7159 20.14V11.86C26.7159 11.5948 26.6119 11.3404 26.4267 11.1529L20.639 5.29289C20.4538 5.10536 20.2026 5 19.9406 5ZM6.96286 12.2742L12.172 7H19.5315L24.7406 12.2742V19.7258L19.5315 25H12.172L6.96286 19.7258V12.2742ZM14.8641 12V16C14.8641 16.5523 15.3063 17 15.8517 17C16.3972 17 16.8394 16.5523 16.8394 16V12C16.8394 11.4477 16.3972 11 15.8517 11C15.3063 11 14.8641 11.4477 14.8641 12ZM16.5501 18.2929C16.9358 18.6834 16.9358 19.3166 16.5501 19.7071C16.1644 20.0976 15.5391 20.0976 15.1534 19.7071C14.7677 19.3166 14.7677 18.6834 15.1534 18.2929C15.5391 17.9024 16.1644 17.9024 16.5501 18.2929Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_289_1289)">
        <rect x="4" y="4" width="23.7037" height="24" fill="white" />
      </g>
    </svg>
  );
};

export default DisclaimerIcon;
