import cx from 'clsx';
import React from 'react';
import styles from './Table.module.scss';

export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  fixed?: boolean;
  fullWidth?: boolean;
}

export const Table: React.FC<TableProps> = ({ fixed, fullWidth, children, className, ...rest }) => (
  <table
    className={cx(styles.table, fullWidth && styles.fullWidth, fixed && styles.fixed, className)}
    {...rest}
  >
    {children}
  </table>
);

export const HorizontalScrollTable: React.FC<React.HTMLAttributes<HTMLTableElement>> = ({
  children,
}) => (
  <div className={styles.horizontalScrolLTable}>
    <Table>{children}</Table>
  </div>
);

export const Thead: React.FC<React.HTMLAttributes<HTMLTableSectionElement>> = ({
  children,
  ...rest
}) => (
  <thead className={styles.thead} {...rest}>
    {children}
  </thead>
);

export const HeaderRow: React.FC<React.HTMLAttributes<HTMLTableRowElement>> = ({
  children,
  ...rest
}) => (
  <tr className={styles.headerRow} {...rest}>
    {children}
  </tr>
);

export const HeaderButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = props => (
  <button type="button" className={styles.headerButton} {...props} />
);

// Left table TH elements
interface LeftTableTHProps extends React.ThHTMLAttributes<HTMLTableHeaderCellElement> {
  onClick?: () => void;
}

export const LeftTableTH: React.FC<LeftTableTHProps> = ({
  children,
  className,
  onClick,
  ...rest
}) => (
  <th className={cx(styles.leftTableTH, onClick && styles.clickable, className)} {...rest}>
    {onClick && <HeaderButton onClick={() => onClick()}>{children}</HeaderButton>}
    {!onClick && children}
  </th>
);

export const ModelCodeHeaderRow: React.FC<React.HTMLAttributes<HTMLTableRowElement>> = ({
  children,
  className,
}) => <tr className={cx(styles.modelCodeHeaderRow, className)}>{children}</tr>;

export const TwoTableWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className={styles.twoTableWrapper} {...rest}>
    {children}
  </div>
);
