import cx from 'clsx';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React from 'react';
import { getContentState } from '../../utils/richTextUtils';
import styles from './rtehtml.module.scss';

export interface RTEhtmlProps {
  content: string;
}

const RTEhtml = (content: RTEhtmlProps) => {
  const editorState = EditorState.createWithContent(getContentState(content.content));
  const html = stateToHTML(editorState.getCurrentContent());

  return <div className={cx(styles.root)} dangerouslySetInnerHTML={{ __html: html }} />;
};
export default RTEhtml;
