/* eslint-disable react/no-array-index-key */

import React from 'react';
/**
 * Add word break opportunities to certain tokens ('/' by default)
 * @param value
 */
const addWBR = (value: string): JSX.Element => {
  if (!value) {
    return <></>;
  }

  const split = value.split('/');
  const items: JSX.Element[] = [];
  split.forEach((item, index) => {
    items.push(<>{item}</>);
    if (index < split.length - 1) {
      items.push(
        <>
          /
          <wbr />
        </>
      );
    }
  });

  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}
    </>
  );
};

export default addWBR;
