import React from 'react';
import styles from './Wayfinding.module.scss';

type Props = {
  year: string;
  seriesName: string;
  showModels?: boolean;
  renderBackButton: React.ReactElement;
};

const Wayfinding: React.FC<Props> = ({ year, renderBackButton, seriesName }) => {
  return (
    <div className={styles.root}>
      <span className={styles.back}>{renderBackButton}</span>
      <span className={styles.line} />
      <div className={styles.model}>
        <span>{year}</span>
        <span className={styles.series}>{seriesName}</span>
      </div>
    </div>
  );
};

export default Wayfinding;
