import cx from 'clsx';
import React, { HTMLAttributes } from 'react';
import styles from './CheckboxLabel.module.scss';

interface CheckboxLabelProps extends HTMLAttributes<HTMLSpanElement> {
  small?: boolean;
}

const CheckboxLabel = ({ small, ...rest }: CheckboxLabelProps) => {
  const getCssClass = () => {
    return cx(styles.chboxLabel, small && styles.small);
  };

  return <span className={getCssClass()} {...rest} />;
};

export default CheckboxLabel;
