import cx from 'clsx';
import React from 'react';
import ReactDOM from 'react-dom';
import { animated, useTransition } from 'react-spring';
import styles from './Modal.module.scss';

export interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  open: boolean;
  onClose: () => void;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'auto';
}

const Modal = ({ open, onClose = () => {}, children, className, size }: ModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 50 },
  });

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        setIsOpen(true);
      });
    }
    return setIsOpen(false);
  }, [open, setIsOpen]);

  const ModalContent = (
    <section className={styles.modal}>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props} className={styles.modalContainer}>
              <div className={styles.modalDialog}>
                <div role="presentation" className={styles.backdrop} onClick={() => onClose()} />
                <div
                  className={cx(
                    className,
                    styles.dialog,
                    size === 'md' && styles.medium,
                    size === 'lg' && styles.large,
                    size === 'xl' && styles.extraLarge,
                    size === 'auto' && styles.auto
                  )}
                  style={props}
                >
                  <div className={styles.content}>{children}</div>
                </div>
              </div>
            </animated.div>
          )
      )}
    </section>
  );

  return open ? ReactDOM.createPortal(ModalContent, document.body) : null;
};

export default Modal;
