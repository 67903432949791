import React, { ReactNode, Ref } from 'react';
import { DropdownContent, IconTextButton } from '..';
import styles from './DropdownEdit.module.scss';
import DropdownEditAdd from './DropdownEditAdd';

interface DropdownEditProps {
  open: boolean;
  addBtnText: string;
  onAdd: (value: string) => void;
  renderList: () => ReactNode;
}

const DropdownEdit = React.forwardRef(
  ({ open, addBtnText, onAdd, renderList }: DropdownEditProps, ref: Ref<any>) => {
    const [addItem, setAddItem] = React.useState(false);
    const [value, setValue] = React.useState('');
    const addValue = (val: string) => {
      setValue(val);
      if (value !== val) {
        onAdd(val);
      }
    };

    return (
      <>
        <DropdownContent open={open} ref={ref}>
          <>
            <div className={styles.scrollContainer}>
              <ul className={styles.list}>{renderList()}</ul>
            </div>
            <footer className={styles.dropdownEditFooter}>
              {!addItem && (
                <IconTextButton
                  smallIcon
                  icon="plus"
                  text={addBtnText}
                  onClick={() => setAddItem(true)}
                />
              )}
              {addItem && <DropdownEditAdd onClick={val => addValue(val)} />}
            </footer>
          </>
        </DropdownContent>
      </>
    );
  }
);

export default DropdownEdit;
