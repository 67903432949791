import cx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { useComponentVisible } from '../../hooks';
import onEnterPress from '../../utils/events/onEnterPress/onEnterPress';
import styles from './TokenizedTextArea.module.scss';
import { TokenizedTextAreaProps } from './TokenizedTextAreaProps';

const TokenizedTextArea: React.FC<TokenizedTextAreaProps> = ({
  size = '',
  value = '',
  tokens,
  onBlurCallback = () => {},
  allowEmpty,
  className,
  displayClassName,
  disabled,
  singleLine,
  keepInputShown,
  placeholder,
  displayValue,
  textAreaClassName,
}) => {
  const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const onClick = () => setIsComponentVisible(true);
  const [text, setText] = useState<string>(value || '');
  const [data, setData] = useState<SuggestionDataItem[]>([]);

  // create a ref for the suggestions portal
  const containerRef = useRef<HTMLDivElement>(null);
  /**
   * Replace the disclaimer tokens to match the markup
   * @param value
   */
  const replace = (val: string) => {
    // TODO return value.replace(new RegExp('\\[', 'g'), '@[');
    return val;
  };

  useEffect(() => {
    if (value) {
      // replace the value with token markup format
      setText(replace(value));
    } else {
      setText('');
    }
    if (tokens && tokens.length) {
      setData(tokens);
    } else {
      setData([]);
    }
  }, [value, tokens]);

  /**
   * On change event handler to update the text
   * @param newValue
   */
  const onChange = (newValue: string) => {
    setText(newValue);
  };

  /**
   * On blur event handler to save the changes
   * @param text
   * @param clickedSuggestion
   */
  const handleOnBlur = (vmText: string, clickedSuggestion: boolean) => {
    if (!clickedSuggestion && (allowEmpty || vmText !== '')) {
      setText(vmText);
      setIsComponentVisible(false);
      onBlurCallback(vmText);
    } else if (clickedSuggestion) {
      setIsComponentVisible(true);
    } else {
      setText(value || '');
      setIsComponentVisible(false);
    }
  };

  /**
   * Transform the value as a rich text markup with disclaimer tokens
   * @param value
   */
  const displayValueFn = (vmValue?: string | string[] | number) => {
    if (displayValue) {
      return displayValue;
    }

    if (vmValue) {
      return <span>{vmValue}</span>;
    }

    return <span />;
  };

  return (
    <div
      onFocus={onClick}
      onClick={onClick}
      tabIndex={0}
      className={cx(styles.root, styles[`root-${size}`], className)}
      role="textbox"
      ref={containerRef}
      onKeyPress={e => onEnterPress(e, onClick)}
    >
      {(disabled || (!isComponentVisible && text && !keepInputShown)) && (
        <div className={cx(styles.display, displayClassName)}>{displayValueFn(text)}</div>
      )}
      {!disabled && (isComponentVisible || !text || keepInputShown) && (
        <div className={cx(styles.editor, textAreaClassName)}>
          <MentionsInput
            autoFocus={!allowEmpty || isComponentVisible}
            singleLine={singleLine}
            placeholder={placeholder}
            value={text}
            className="mentions"
            classNames={styles}
            onChange={e => onChange(e.target.value)}
            onBlur={(event, clickedSuggestion) => {
              handleOnBlur(event.currentTarget.value.trim(), clickedSuggestion);
            }}
            allowSuggestionsAboveCursor
            suggestionsPortalHost={containerRef.current as Element}
          >
            <Mention
              trigger="["
              data={data}
              className={styles.mentions__mention}
              markup="[__display__]"
              displayTransform={display => {
                return `[${display}]`;
              }}
            />
          </MentionsInput>
        </div>
      )}
    </div>
  );
};

export default TokenizedTextArea;
