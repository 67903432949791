import React, { HTMLAttributes, ReactNode } from 'react';
import Checkbox, { CheckboxProps } from '../../Checkbox/Checkbox';
import { IconTextButton } from '../../IconTextButton';
import { Tooltip } from '../../Tooltip';
// import NumberFormat from '../../NumberFormat';
import { TableCell, TableCellProps } from '../components/TableCell/TableCell';
import { TableRow, TableRowProps } from '../components/TableRow/TableRow';
import { Table, TableProps } from '../Table';
import styles from './AccessoriesTable.module.scss';

export const AccessoriesTable = (props: TableProps) => (
  <Table className={styles.table} {...props} />
);

export const AccessoriesTableTrimHeaderRow = (props: HTMLAttributes<HTMLDivElement>) => (
  <div className={styles.trimHeaderRow} {...props} />
);

export const AccessoriesTableHeaderCell = (props: TableCellProps) => (
  <td className={styles.headerCell} {...props} />
);

export const AccessoriesTableTrimHeaderName = (props: TableCellProps) => (
  <div className={styles.trimHeaderNameCell} {...props} />
);

export const AccessoriesTableCellSpacer = (props: TableCellProps) => (
  <td className={styles.cellSpacer} {...props} />
);

interface AccessoriesTableHeaderCellProps extends TableCellProps {
  type?: ReactNode;
  interiorName?: ReactNode;
  interiorCode?: ReactNode;
  modelCode: number | string;
  modelCodes?: ReactNode;
  gradeCode?: number | string;
  interiorItemsLength?: number;
  rejectNotes?: string;
  isExtraCost?: string;
  onClick?: () => void;
  leftInititalPosition?: number;
}

export const AccessoriesTableHeaderDetail = ({
  type,
  interiorName,
  modelCode,
  gradeCode,
  rejectNotes,
  // isExtraCost = '',
  onClick,
  ...rest
}: AccessoriesTableHeaderCellProps) => (
  <div className={styles.headerDetail} {...rest}>
    {gradeCode !== undefined && <div className={styles.modelCode}>{gradeCode}</div>}
    <div className={styles.modelCode}>{modelCode}</div>
    <section className={styles.status}>
      {rejectNotes && <IconTextButton icon="purpleCircle" smallIcon text="" disabled />}
    </section>
  </div>
);

export const AccessoriesTableHeaderRow = (props: HTMLAttributes<HTMLDivElement>) => (
  <div className={styles.headerRow} {...props} />
);

export const AccessoriesTableBodyRow = (props: TableRowProps) => (
  <TableRow className={styles.bodyRow} {...props} />
);

export const CheckboxCell = ({ id, checked, onChange, disabled }: CheckboxProps) => (
  <TableCell colType="applicability" center className={styles.test}>
    <Checkbox
      id={id}
      className={styles.checkboxCell}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  </TableCell>
);

export const AccessoriesTableModelCodeRow = ({
  modelCodes,
  interiorItemsLength,
  leftInititalPosition,
  ...rest
}: AccessoriesTableHeaderCellProps) => {
  const codes = modelCodes && modelCodes.toString().replace(/,/g, '');
  const codesLength = Number(interiorItemsLength) * 28;
  const shortList =
    codes && codes.length > codesLength ? `${codes.substring(0, codesLength)}...` : codes;

  return (
    <div className={styles.modelCodesWrapper} {...rest}>
      <Tooltip
        leftInititalPosition={leftInititalPosition}
        toggleElement={<div className={styles.modelCodesToggle}>{shortList}</div>}
        popoverElement={<div className={styles.modelCodesPopover}>{codes}</div>}
      />
    </div>
  );
};
