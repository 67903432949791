import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import { emitter, uiBlockEventId } from './uiBlock';
import styles from './UiBlocker.module.scss';

interface UiBlockerProps {}

const UiBlocker = ({ children }: React.PropsWithChildren<UiBlockerProps>) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const updateUiBlock = (start: boolean) => {
    setIsBlocking(start);
  };

  useEffect(() => {
    emitter.on(uiBlockEventId, updateUiBlock);

    return () => emitter.off(uiBlockEventId, updateUiBlock);
  }, []);

  return (
    <div className={cx(isBlocking && styles.blockUi)}>
      {children}
      {isBlocking && (
        <div className={styles.blockUiContainer}>
          <div className={styles.blockUiOverlay} />
        </div>
      )}
    </div>
  );
};

export default UiBlocker;
