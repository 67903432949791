import React, { useState } from 'react';
import styles from './Notes.module.scss';
import { Button } from '../Button';

interface NotesProps {
  notes: string;
  readOnly?: boolean;
  onUpdate?: (notes: string) => void;
  closePopover?: () => void;
}

const Notes = (props: NotesProps) => {
  const { notes, readOnly, closePopover, onUpdate } = props;
  const [formNotes, setFormNotes] = useState(notes || '');

  return (
    <section className={styles.notes}>
      <header className={styles.header}>Notes</header>
      {readOnly && <div>{formNotes}</div>}
      {!readOnly && (
        <>
          <div className={styles.body}>
            <textarea
              placeholder="Placeholder"
              className={styles.textarea}
              onChange={e => setFormNotes(e.currentTarget.value)}
              value={formNotes}
            />
          </div>
          <footer className={styles.footer}>
            <Button
              variant="transparent"
              onClick={() => {
                if (typeof closePopover === 'function') { closePopover(); }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (typeof onUpdate === 'function') { onUpdate(formNotes.trim()); }                
                if (typeof closePopover === 'function') { closePopover(); }
              }}
            >
              Save
            </Button>
          </footer>
        </>
      )}
    </section>
  );
};

export default Notes;
