import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { ReactNode, useCallback, useState } from 'react';
import { Button } from '../../../Button';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import DateComponent from '../../../DateComponent/DateComponent';
import IconTextButton from '../../../IconTextButton/IconTextButton';
import { Modal } from '../../../Modal';
import { TableRow } from '../../../Table/components/TableRow/TableRow';
import RemoveDraftModal from '../RemoveDraftModal';
import UploadColumn from './UploadColumn';
import styles from './VehicleTableRow.module.scss';

type TDLinkType = {
  className: string;
  click?: () => void;
  children?: ReactNode;
  id?: string;
  dataTestid?: string;
};

const TDLink = ({ className, click, children, id, dataTestid }: TDLinkType) => {
  return (
    <td
      role="presentation"
      className={className}
      onClick={click}
      onKeyPress={() => {}}
      id={id}
      data-testid={dataTestid}
    >
      {children}
    </td>
  );
};

interface VehicleTableRowProps {
  year: number;
  version: string;
  notes: string;
  seriesId: string;
  seriesName: string;
  useNewStatus?: boolean;
  date?: string;
  statusIndicator?: ReactNode;
  showPreviewButton?: boolean;
  showPublishButton?: boolean;
  disablePublishButton?: boolean;
  disablePreviewButton?: boolean;
  showCreateDraftButton?: boolean;
  showRemoveDraftButton?: boolean;
  onRowClick?: (seriesId: string, year: number) => void;
  onDeleteItem: (seriesId: string, modelYear: number) => void;
  onCreateDraft: (seriesId: string, modelYear: number) => void;
  onPublish?: (seriesId: string, modelYear: number) => void;
  onPreview?: (seriesId: string, modelYear: number) => void;
  publishButtonTitle?: string;
  previewButtonTitle?: string;
  alwaysShowIcons?: boolean;
  isSpanish?: boolean;
  englishVersion?: number;
  spanishVersion?: number;
  onDownload?: (seriesId: string, seriesName: string, enVersion: number, year: number) => void;
  onFileUpload?: (file?: File, resultJSON?: object, seriesId?: string, year?: number) => void;
  statusColumn?: boolean;
  outOfSyncColumn?: boolean;
  status?: ReactNode;
  outOfSyncStatus?: ReactNode;
  adminOutOfSyncColumn?: boolean;
  adminOutOfSyncStatus?: ReactNode;
  className?: string;
}

const VehicleTableRow = ({
  year,
  version,
  notes,
  date,
  seriesId,
  seriesName,
  useNewStatus,
  statusIndicator,
  showPreviewButton,
  showPublishButton,
  disablePublishButton,
  disablePreviewButton,
  showCreateDraftButton,
  showRemoveDraftButton,
  onDeleteItem,
  onCreateDraft,
  onPublish,
  onPreview,
  onRowClick = () => undefined,
  publishButtonTitle,
  previewButtonTitle,
  alwaysShowIcons,
  isSpanish,
  englishVersion = 0,
  spanishVersion = 0,
  onDownload,
  onFileUpload,
  statusColumn,
  outOfSyncColumn,
  status,
  outOfSyncStatus,
  adminOutOfSyncColumn,
  adminOutOfSyncStatus,
  className,
}: VehicleTableRowProps) => {
  const publishTitle = publishButtonTitle ?? 'Publish';
  const previewTitle = previewButtonTitle ?? 'Preview';
  const [showIcons, setShowIcons] = useState(false);
  const [removeDraft, setRemoveDraft] = useState(false);
  const [publishATModal, setPublishATModal] = useState(false);
  const [previewATModal, setPreviewATModal] = useState(false);

  const handlerAllowLinkRedirect = useCallback(() => onRowClick(seriesId, year), [
    onRowClick,
    seriesId,
    year,
  ]);

  return (
    <TableRow
      zebra
      onMouseEnter={() => setShowIcons(true)}
      onMouseLeave={() => setShowIcons(false)}
      className={className}
    >
      <TDLink className={styles.tableText} click={handlerAllowLinkRedirect}>
        <span className={styles.content}>{year}</span>
      </TDLink>

      <TDLink className={styles.tableText} click={handlerAllowLinkRedirect}>
        <span className={styles.content}>
          {date ? <DateComponent format="MM/DD/YY">{date}</DateComponent> : '——'}
        </span>
      </TDLink>

      <TDLink className={styles.tableText} click={handlerAllowLinkRedirect}>
        {isSpanish ? (
          <>
            <div>{`EN: V${englishVersion}`}</div>
            <div>{`ES: V${spanishVersion}`}</div>
          </>
        ) : (
          <span className={styles.content}>{`V${version}`}</span>
        )}
      </TDLink>

      {isSpanish ? (
        <>
          <UploadColumn
            seriesId={seriesId}
            year={year}
            englishVersion={englishVersion}
            onFileUpload={onFileUpload}
            onRowClick={handlerAllowLinkRedirect}
          />

          <TDLink
            className={styles.tableText}
            click={!onDownload ? handlerAllowLinkRedirect : undefined}
          >
            {onDownload && (
              <div
                role="presentation"
                onKeyPress={() => onDownload(seriesId, seriesName, englishVersion, year)}
                onClick={() => onDownload(seriesId, seriesName, englishVersion, year)}
              >
                <span className={styles.actionLabel}>
                  Download
                  <FontAwesomeIcon className={styles.upDown} icon={faArrowDown} />
                </span>
              </div>
            )}
          </TDLink>
        </>
      ) : (
        <>
          <TDLink className={styles.tableText} click={handlerAllowLinkRedirect}>
            {notes}
          </TDLink>

          <TDLink className={styles.tableText} click={handlerAllowLinkRedirect}>
            {useNewStatus && statusIndicator}
          </TDLink>
        </>
      )}
      {statusColumn && (
        <TDLink className={styles.tableTextStatusColumn} click={handlerAllowLinkRedirect}>
          {status}
        </TDLink>
      )}
      {adminOutOfSyncColumn && (
        <TDLink
          className={styles.tableText}
          click={handlerAllowLinkRedirect}
          dataTestid="VehicleTableRow-adminOutOfSyncColumn"
          id="VehicleTableRow-adminOutOfSyncColumn"
        >
          {adminOutOfSyncStatus}
        </TDLink>
      )}
      {outOfSyncColumn && (
        <TDLink className={styles.tableText} click={handlerAllowLinkRedirect}>
          {outOfSyncStatus}
        </TDLink>
      )}
      <TDLink className={cx(styles.tableText, styles.actionBtns)}>
        <span
          className={cx(
            styles.actionBtnsWrapper,
            !showIcons && !alwaysShowIcons && styles.hideIcon
          )}
        >
          {showPreviewButton && (
            <Button
              variant="secondary"
              onClick={() => setPreviewATModal(true)}
              disabled={disablePreviewButton}
            >
              {previewTitle}
            </Button>
          )}
          {showPublishButton && (
            <Button
              variant="secondary"
              onClick={() => setPublishATModal(true)}
              disabled={disablePublishButton}
            >
              {publishTitle}
            </Button>
          )}
          <div className={styles.editRemoveBtnContainer}>
            {showCreateDraftButton && (
              <IconTextButton
                icon="edit"
                text=""
                className={styles.addModelYearCta}
                onClick={() => onCreateDraft(seriesId, year)}
              />
            )}
            {showRemoveDraftButton && (
              <IconTextButton
                icon="remove"
                text=""
                className={styles.removeCta}
                onClick={() => setRemoveDraft(true)}
              />
            )}
          </div>
        </span>
        <Modal open={removeDraft} onClose={() => setRemoveDraft(false)}>
          <RemoveDraftModal
            close={() => setRemoveDraft(false)}
            onSubmit={() => onDeleteItem(seriesId, year)}
          />
        </Modal>
        <ConfirmModal
          headerText="Publish Series"
          bodyText={`Are you sure you want to publish the ${year} ${seriesName}?`}
          confirmButtonText="Publish"
          open={publishATModal}
          onClose={() => setPublishATModal(false)}
          onConfirm={() => onPublish && onPublish(seriesId, year)}
        />
        <ConfirmModal
          headerText="Preview Series"
          bodyText={`Are you sure you want to preview the ${year} ${seriesName}?`}
          confirmButtonText="Preview"
          open={previewATModal}
          onClose={() => setPreviewATModal(false)}
          onConfirm={() => onPreview && onPreview(seriesId, year)}
        />
      </TDLink>
    </TableRow>
  );
};

export default VehicleTableRow;
