import generateHtml from './generateHtml';

const rteToHtml = (value?: string) => {
  if (!value) {
    return '';
  }
  try {
    return generateHtml(JSON.parse(value).content);
  } catch (e) {
    if (typeof value === 'string') {
      return value.replace(/\n/g, '<br>');
    }
    return value;
  }
};

export default rteToHtml;
