import React, { ReactNode } from 'react';
import cx from 'clsx';
import { Tabs } from '../Tabs';
import styles from './SecondaryHeader.module.scss';

type SecondaryHeaderProps = {
  tabs: string[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  renderButtons: () => ReactNode;
  className?: string;
};

const SecondaryHeader: React.FC<SecondaryHeaderProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  renderButtons,
  className,
}) => {
  return (
    <div className={cx(styles.root, className)}>
      <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabs} />
      <div className={styles.buttons}>{renderButtons()}</div>
    </div>
  );
};

export default SecondaryHeader;
