import cx from 'clsx';
import React, { useRef, useState } from 'react';

import styles from './Tooltip.module.scss';

interface TooltipProps {
  popoverClass?: string;
  toggleElement: React.ReactElement;
  popoverElement?: React.ReactElement;
  // popoverText?: string;
  align?: 'right' | 'left';
  popoverElementClass?: string;
  // defaultOpen?: boolean;
  leftInititalPosition?: number;
}

const Tooltip = ({
  popoverClass,
  toggleElement,
  popoverElement,
  // popoverText,
  popoverElementClass,
  align,
  // defaultOpen = false,
  leftInititalPosition = 0,
}: TooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [leftPosition, setLeftPosition] = useState(0);

  const handlePosition = () => {
    setOpen(!open);
    const getLeftPosition = ref.current?.getBoundingClientRect().left;
    if (getLeftPosition) {
      setLeftPosition(getLeftPosition - leftInititalPosition);
    }
  };

  return (
    <>
      <div ref={ref} className={cx([popoverClass, styles.popover])}>
        {React.cloneElement(toggleElement, {
          onMouseEnter: () => handlePosition(),
          onMouseLeave: () => handlePosition(),
        })}
      </div>
      {open && (
        <div
          style={{ left: leftPosition }}
          className={cx(
            {
              [styles.popoverElement]: true,
              [styles.popoverDefault]: true,
            },
            styles[`align-${align}`],
            popoverElementClass
          )}
        >
          {popoverElement}
        </div>
      )}
    </>
  );
};

export default Tooltip;
