import React, { SVGProps } from 'react';

const CollapseNavArrowIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_289_1407)">
        <circle cx="12" cy="12" r="12" transform="matrix(0 1 1 0 5 3)" fill="#F2F2F2" />
        <circle cx="12" cy="12" r="12" transform="matrix(0 1 1 0 5 3)" stroke="#CDD1D4" />
      </g>
      <path d="M19.9999 20L14.9999 15.2926L19.9999 10" stroke="#89969F" strokeWidth="2" />
      <defs>
        <filter
          id="filter0_d_289_1407"
          x="0.5"
          y="0.5"
          width="33"
          height="33"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.352941 0 0 0 0 0.407843 0 0 0 0 0.447059 0 0 0 0.1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_289_1407" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_289_1407"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CollapseNavArrowIcon;
