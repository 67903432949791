import cx from 'clsx';
import React from 'react';
import styles from './Accordion.module.scss';
import { AccordionSectionProps } from './AccordionProps';

const AccordionSection = ({ expand, ...rest }: AccordionSectionProps) => {
  return <div className={cx(styles.accordionSection, expand && styles.expand)} {...rest} />;
};

export default AccordionSection;
