import React from 'react';
import styles from './Toast.module.scss';

const CloseToastButton = ({ closeToast }: any) => (
  <button
    type="button"
    aria-label="Close Toast"
    className={styles.toastClose}
    onClick={closeToast}
  />
);

export default CloseToastButton;
