import React, { SVGProps } from 'react';

const GstIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 14V10C26 8.9 25.1 8 24 8H8C6.9 8 6.01 8.9 6.01 10V14C7.11 14 8 14.9 8 16C8 17.1 7.11 18 6 18V22C6 23.1 6.9 24 8 24H24C25.1 24 26 23.1 26 22V18C24.9 18 24 17.1 24 16C24 14.9 24.9 14 26 14ZM24 12.54C22.81 13.23 22 14.53 22 16C22 17.47 22.81 18.77 24 19.46V22H8V19.46C9.19 18.77 10 17.47 10 16C10 14.52 9.2 13.23 8.01 12.54L8 10H24V12.54ZM13.07 20L16 18.12L18.93 20L18.04 16.64L20.73 14.44L17.26 14.23L16 11L14.73 14.22L11.26 14.43L13.95 16.63L13.07 20Z"
        fill="white"
      />
    </svg>
  );
};

export default GstIcon;
