import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { useDebounce } from '../../hooks';
import Input from '../Input/Input';
import styles from './SearchInput.module.scss';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  value?: string;
  onSearch: (value: string) => void;
  isByClick?: boolean;
  isByEnter?: boolean;
}

const SearchInput = ({
  placeholder = 'Search',
  value = '',
  onSearch,
  isByClick = false,
  isByEnter = false,
  className,
}: SearchInputProps) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [setText, value]);

  const { debounce } = useDebounce({ delay: 1000 });
  const handleOnChange = (val: string) => {
    setText(val);
    if (!isByClick && !isByEnter) {
      debounce(() => {
        onSearch(val);
      });
    }
  };

  const handleOnClick = () => {
    if (isByClick) {
      onSearch(text);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isByEnter && e.key === 'Enter') {
      onSearch(text);
    }
  };

  return (
    <div className={styles.container}>
      <Input
        className={cx(className, styles.searchInput)}
        placeholder={placeholder}
        value={text}
        onChange={e => handleOnChange(e.currentTarget.value)}
        onKeyDown={handleOnKeyDown}
      />
      <div
        role="button"
        tabIndex={0}
        className={cx(styles.searchClickBox, { [styles.hideSearchClickBox]: !isByClick })}
        onClick={handleOnClick}
        onKeyDown={handleOnClick}
      >
        {/* This div is clickable */}
      </div>
    </div>
  );
};

export default SearchInput;
