import React from 'react';

type TabPanelProps = {
  tab: string;
  selected: boolean;
};

const TabPanel: React.FC<TabPanelProps> = ({ children, selected, tab }) => (
  <div tabIndex={0} role="tabpanel" id={`${tab}-tabpanel`} style={{ outline: 'none' }}>
    {selected && children}
  </div>
);

export default TabPanel;
