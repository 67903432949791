import cx from 'clsx';
import React from 'react';
import styles from './Radio.module.scss';

export interface RadioProps {
  label: string;
  value: string;
  className?: string;
  name?: string;
  selectedValue?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const Radio = ({
  label,
  value,
  className,
  name,
  disabled = false,
  selectedValue,
  onChange = () => {},
}: RadioProps) => {
  return (
    <label htmlFor={name} className={cx(styles.radio, styles.default, className)}>
      <input
        type="radio"
        name={name}
        disabled={disabled}
        value={value}
        defaultChecked={value === selectedValue}
        onChange={e => onChange(e.currentTarget.value)}
      />
      {label}
    </label>
  );
};

export default Radio;
