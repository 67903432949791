import { BlockMap } from '../types';

const blockMap: BlockMap = {
  'unordered-list-item': {
    tag: 'li',
    wrapperTag: 'ul',
  },
};

export default blockMap;
