import cx from 'clsx';
import React, { LegacyRef } from 'react';
import styles from './TableRow.module.scss';

export interface TableRowBaseProps extends React.HTMLAttributes<HTMLTableRowElement> {
  zebra?: boolean;
  hoverShadow?: boolean;
  className?: string;
  innerRef?: LegacyRef<HTMLTableRowElement>;
}

const TableRowBase = ({ zebra, hoverShadow, className, innerRef, ...rest }: TableRowBaseProps) => {
  return (
    <tr
      ref={innerRef}
      className={cx(styles.tr, zebra && styles.zebra, hoverShadow && styles.hoverShadow, className)}
      {...rest}
    />
  );
};

export default TableRowBase;
