import cx from 'clsx';
import React from 'react';
import RTEditor, { RTEditorProps } from '../RTEditor';
import styles from './multiLineRTEditor.module.scss';

interface MultiLineRTEditorProps extends RTEditorProps {}

const MultiLineRTEditor = ({ editorStyles, ...rest }: MultiLineRTEditorProps) => {
  return <RTEditor editorStyles={cx(styles.root, editorStyles)} {...rest} />;
};

export default MultiLineRTEditor;
