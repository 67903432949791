import cx from 'clsx';
import React, { HTMLAttributes } from 'react';
import styles from './Dropdown.module.scss';

const Dropdown = (props: HTMLAttributes<HTMLDivElement>) => {
  const { children, className } = props;

  return <div className={cx([styles.dropdown, className])}>{children}</div>;
};

export default Dropdown;
