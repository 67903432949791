import { AttributedString, CSEntityMap, CSEntityRanges } from '../types';
import styleMap from './styleMap';

const processEntityRanges = (
  attrString: AttributedString[],
  entityRanges: CSEntityRanges[],
  entityMap: CSEntityMap
) => {
  entityRanges.forEach(entityRange => {
    const startIndex = entityRange.offset;
    const endIndex = entityRange.offset + entityRange.length;
    const eMap = entityMap[entityRange.key];
    const sMap = styleMap[eMap.type];
    if (!sMap || !eMap) {
      return;
    }

    attrString.forEach(string => {
      // start filling entity range data
      if (string.index === startIndex) {
        const attributes = Object.entries(eMap.data).map(([key, value]) => `${key}="${value}"`);
        string.startingTags = [`<${sMap.tag} ${attributes.join(' ')}>`, ...string.startingTags];
      }

      if (string.index === endIndex) {
        string.endingTags = [...string.endingTags, `</${sMap.tag}>`];
      }
    });
  });
};

export default processEntityRanges;
