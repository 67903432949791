import cx from 'clsx';
import React from 'react';
import { animated, useTransition } from 'react-spring';
import useComponentVisible from '../../hooks/useComponentVisible/useComponentVisible';
import styles from './Popover.module.scss';

interface PopoverProps {
  popoverClass?: string;
  toggleElement: React.ReactElement;
  popoverElement?: React.ReactElement;
  popoverText?: string;
  align?: 'right' | 'left';
  msrpPopover?: boolean;
  popoverElementClass?: string;
  defaultOpen?: boolean;
}

const Popover = ({
  popoverClass,
  toggleElement,
  popoverElement,
  popoverText,
  popoverElementClass,
  align,
  msrpPopover,
  defaultOpen = false,
}: PopoverProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(defaultOpen);

  const transitions = useTransition(isComponentVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  });

  return (
    <div ref={ref} className={cx([popoverClass, styles.popover])}>
      {React.cloneElement(toggleElement, {
        onClick: () => setIsComponentVisible(!isComponentVisible),
      })}
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className={cx(
                {
                  [styles.popoverElement]: true,
                  [styles.popoverDefault]: true,
                },
                styles[`align-${align}`],
                styles[msrpPopover ? `msrpAlign-${align}` : ''],
                popoverElementClass
              )}
            >
              {popoverElement &&
                React.cloneElement(popoverElement, {
                  closePopover: () => setIsComponentVisible(false),
                })}
              {popoverText}
            </animated.div>
          )
      )}
    </div>
  );
};

export default Popover;
