import { useState } from 'react';

const DEFAULT_DELAY = 500;

type DebounceProps = {
  delay?: number;
};

const useDebounce = (
  {
    // props
    delay,
  }: DebounceProps = {
    // defaults
    delay: DEFAULT_DELAY,
  }
) => {
  const { clearTimeout, setTimeout } = window;
  const [map] = useState<Map<string, number>>(new Map<string, number>());

  return {
    debounce: (fn: () => void, id = 'unmapped') => {
      clearTimeout(map.get(id));
      map.set(
        id,
        setTimeout(() => {
          fn();
        }, delay)
      );
    },
  };
};

export default useDebounce;
