import { AttributedString, BlockMapItem } from '../types';

// if tag exists, use opening tag if previous tag is null
//   if previous is set, and is the same tag, do nothing
// if tag null, close out previous tag if previous tag is not null
//    if previous is null, don't wrap

const processBlockType = (
  attrString: AttributedString[],
  block: BlockMapItem | null,
  lastWrapperTag: string | null,
  isLastBlock: boolean
) => {
  const tag = block?.tag ?? null;
  const newWrapperTag = block?.wrapperTag ?? null;

  attrString.forEach(string => {
    // first char
    if (string.index === 0) {
      if (tag !== null) {
        string.startingTags = [`<${tag}>`, ...string.startingTags];
      }

      if (newWrapperTag !== lastWrapperTag && newWrapperTag !== null) {
        string.startingTags = [`<${newWrapperTag}>`, ...string.startingTags];
      }
    }

    // last char
    if (string.index === attrString.length - 1) {
      if (tag !== null) {
        string.endingTags = [...string.endingTags, `</${tag}>`];
      }

      if (
        (newWrapperTag !== lastWrapperTag && lastWrapperTag !== null) ||
        (lastWrapperTag !== null && lastWrapperTag === newWrapperTag && isLastBlock)
      ) {
        string.endingTags = [...string.endingTags, `</${lastWrapperTag}>`];
      }
    }
  });

  return newWrapperTag;
};

export default processBlockType;
