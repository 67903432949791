import cx from 'clsx';
import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import styles from './Input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDebounce?: () => void;
  onBlurValue?: (value: string) => void;
  required?: boolean;
  maxLength?: number;
  isInvalid?: boolean;
  validate?: (value: string) => void;
}

const Input = React.forwardRef((props: InputProps, ref?: React.Ref<HTMLInputElement>) => {
  const {
    className,
    onChange,
    onDebounce,
    onBlur,
    onBlurValue,
    required,
    value,
    defaultValue,
    maxLength,
    isInvalid,
    validate,
    ...rest
  } = props;
  const { debounce } = useDebounce();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const currentValue = value ?? defaultValue;
    let isValid = true;

    if (required && !currentValue) {
      isValid = false;
      setHasError(true);
    }

    if (isValid && validate) {
      validate(currentValue && typeof currentValue === 'string' ? currentValue.toString() : '');
    }
  }, []);

  useEffect(() => {
    if (isInvalid !== undefined) {
      setHasError(isInvalid);
    }
  }, [isInvalid]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (required) {
      setHasError(!event.currentTarget.value);
    }
    if (validate) {
      validate(event.currentTarget.value);
    }
    if (onChange) {
      onChange(event);
    }
    if (onDebounce) {
      debounce(() => {
        onDebounce();
      });
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.trim();
    if (onBlur) {
      onBlur(event);
    }
  };

  const getCssClass = () => {
    return cx(styles.input, hasError && styles.error, className);
  };

  return (
    <>
      <input
        ref={ref}
        value={value}
        type="text"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        className={getCssClass()}
        defaultValue={defaultValue}
        maxLength={maxLength}
        {...rest}
      />
    </>
  );
});

export default Input;
