import React, { SVGProps } from 'react';

const DphIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7053 3.33537C15.1014 3.11542 15.547 3 16 3C16.453 3 16.8986 3.11542 17.2947 3.33537L27.3147 8.9007C27.5224 9.01622 27.6955 9.18518 27.816 9.39007C27.9365 9.59496 28 9.82834 28 10.066V21.046C27.9999 21.5216 27.8726 21.9884 27.6314 22.3982C27.3902 22.808 27.0437 23.1458 26.628 23.3767L17.2947 28.5634C16.8986 28.7833 16.453 28.8987 16 28.8987C15.547 28.8987 15.1014 28.7833 14.7053 28.5634L5.372 23.3767C4.95647 23.1459 4.61018 22.8083 4.36895 22.3988C4.12773 21.9892 4.00035 21.5227 4 21.0474V10.066C3.99999 9.82834 4.06352 9.59496 4.18402 9.39007C4.30452 9.18518 4.4776 9.01622 4.68533 8.9007L14.7067 3.33537H14.7053Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 9.28204L16 15.9487M16 15.9487L28 9.28204M16 15.9487V29.282"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.6153L22 5.94867M8 16.386L12 18.6153"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DphIcon;
