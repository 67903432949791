import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import Editor from '@draft-js-plugins/editor';
import cx from 'clsx';
import {
  ContentState,
  convertToRaw,
  EditorState,
  getDefaultKeyBinding,
  DraftHandleValue,
  Modifier,
} from 'draft-js';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { getContentState } from '../../utils/richTextUtils';
import styles from './editorStyles.module.scss';
import { defaultSuggestionsFilter } from './mentions';
import {
  getInitialState,
  INIT,
  reducer,
  SET_EDITOR,
  SET_EDITOR_STATE,
  SET_MENTION_OPEN,
  SET_SUGGESTIONS,
  SuggestionType,
} from './RTEditorReducer';

type EditorSize = 'xsmall' | 'small' | 'medium' | 'wide' | 'auto';

export interface RTEditorProps {
  value?: string | null | undefined;
  editorStyles?: string;
  maxLength?: number;
  focusStyles?: string;
  emptyStyles?: string;
  disabled?: boolean;
  placeholder?: string;
  suggestionTypes?: SuggestionType[];
  disableToolbar?: boolean;
  disableMentions?: boolean;
  size?: EditorSize;
  fullHeight?: boolean;
  onBlur?(value: string): void;
  onFocus?(): void;
  handleOnDescriptionChange?: (value: string) => void;
  isForceVisible?: boolean;
  required?: boolean;
  tabIndex?: number;
  forceBorder?: boolean;
}

const getSizeStyle = (size?: EditorSize) => {
  return size ? styles[size] : undefined;
};

/**
 * RTEditor Component
 * @param param0
 */
const RTEditor = ({
  editorStyles,
  focusStyles,
  emptyStyles,
  size,
  fullHeight,
  onBlur,
  onFocus,
  disabled,
  maxLength,
  disableToolbar,
  disableMentions,
  placeholder,
  value,
  isForceVisible = false,
  suggestionTypes,
  required,
  tabIndex,
  forceBorder,
}: RTEditorProps) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const [isFocused, setIsFocused] = useState(isForceVisible);

  const { LinkButton } = state.linkPlugin;
  const { InlineToolbar } = state.inlineToolbarPlugin;
  const { MentionSuggestions } = state.mentionPlugin;

  useEffect(() => {
    // parse into draftjs object
    const initialContent: ContentState = getContentState(value || '');

    dispatch({
      type: INIT,
      payload: {
        editorState: EditorState.createWithContent(initialContent),
        suggestions: suggestionTypes,
      },
    });
  }, [suggestionTypes, value, disabled]);

  const handleCharacterLimit = (pastedText: string) => {
    let validate = false;

    if (maxLength) {
      validate = true;
    }

    if (validate) {
      const currentContent = state.editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
      if (
        currentContentLength >= maxLength! ||
        currentContentLength + pastedText.length > maxLength!
      ) {
        return 'handled';
      }
    }
    return 'not-handled';
  };

  const handleOnChange = (editorState: EditorState) => {
    dispatch({
      type: SET_EDITOR_STATE,
      payload: { editorState },
    });
  };

  const handleOnFocus = () => {
    if (!isForceVisible) {
      setIsFocused(true);
    }
    if (onFocus) {
      onFocus();
    }
  };

  const handleOnBlur = () => {
    let payload: string;
    const currentContent = state.editorState.getCurrentContent();
    if (currentContent.hasText()) {
      const content = convertToRaw(currentContent);
      const text = currentContent.getPlainText();
      payload = JSON.stringify({ content, text });
    } else {
      payload = '';
    }

    if (!isForceVisible) {
      setIsFocused(true);
    }

    if (onBlur) {
      onBlur(payload);
    }
  };

  const focus = () => {
    if (state.editor) {
      state.editor.focus();
    }
  };

  const handleRef = useCallback((editor: Editor | null) => {
    dispatch({ type: SET_EDITOR, payload: { editor } });
  }, []);

  const onMentionSearchChange = ({ value: val }: { value: string }) => {
    dispatch({
      type: SET_SUGGESTIONS,
      payload: { suggestions: defaultSuggestionsFilter(val, state.allSuggestions) },
    });
  };

  const onMentionOpen = (isOpen: boolean) => {
    dispatch({
      type: SET_MENTION_OPEN,
      payload: { isMentionOpen: isOpen },
    });
  };

  const getCssClass = () => {
    return cx(
      styles.baseEditorStyles,
      editorStyles,
      getSizeStyle(size),
      !state.editorState.getCurrentContent().hasText() && cx(styles.bordered, emptyStyles),
      isFocused && cx(styles.bordered, focusStyles),
      forceBorder && styles.bordered,
      required && styles.required,
      fullHeight && styles.fullHeight
    );
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (command === 'handle-backspace') {
      return 'handled';
    }

    return 'not-handled';
  };

  const keyBindingFn = (e: React.KeyboardEvent<Element>) => {
    // When backspace is pressed
    if (e.keyCode === 8) {
      const index = state.editorState.getSelection().getAnchorOffset() - 1;
      const text = state.editorState.getCurrentContent().getPlainText();

      if (index >= 0 && text[index] === ']') {
        let count = index - 1;
        let found = false;

        while (count >= 0 && !found) {
          if (text[count] === '[') {
            found = true;
          } else {
            count -= 1;
          }
        }

        if (found) {
          const disclaimer = text.slice(count + 1, index);

          const newSelection1 = state.editorState.getSelection().merge({
            anchorOffset: count + 1,
            focusOffset: index + 1,
          });

          const newContent = Modifier.replaceText(
            state.editorState.getCurrentContent(),
            newSelection1,
            disclaimer
          );

          const newEditorState = EditorState.push(
            state.editorState,
            newContent,
            'backspace-character'
          );

          const stateWithContentAndSelection = EditorState.forceSelection(
            newEditorState,
            newContent.getSelectionAfter()
          );

          dispatch({
            type: SET_EDITOR_STATE,
            payload: { editorState: stateWithContentAndSelection },
          });

          return 'handle-backspace';
        }
      }
    }
    // Need to return undefined to enable down and up keys on suggestion dropdown. keyBindingFn disables it.
    if (e.keyCode === 38 || e.keyCode === 40) {
      return undefined;
    }

    return getDefaultKeyBinding(e);
  };

  return !state.isLoaded ? null : (
    <section role="presentation" onClick={focus} onKeyPress={() => {}} className={getCssClass()}>
      <Editor
        handlePastedText={handleCharacterLimit}
        handleBeforeInput={handleCharacterLimit}
        readOnly={disabled}
        placeholder={placeholder}
        editorState={state.editorState}
        onChange={handleOnChange}
        onFocus={() => handleOnFocus()}
        onBlur={() => handleOnBlur()}
        plugins={state.plugins}
        tabIndex={tabIndex}
        ref={handleRef}
        keyBindingFn={keyBindingFn}
        handleKeyCommand={handleKeyCommand}
      />
      {!disableToolbar && (
        <InlineToolbar>
          {externalProps => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <LinkButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
            </>
          )}
        </InlineToolbar>
      )}
      {!disableMentions && (
        <MentionSuggestions
          open={state.isMentionOpen}
          onOpenChange={onMentionOpen}
          onSearchChange={onMentionSearchChange}
          suggestions={state.suggestions}
        />
      )}
    </section>
  );
};

export default RTEditor;
