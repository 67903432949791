import React from 'react';
import styles from './VehicleTableRow.module.scss';

interface VehicleTableEmptyRowProps {
  statusColumn?: boolean;
  outOfSyncColumn?: boolean;
  adminOutOfSyncColumn?: boolean;
  className?: string;
}

const VehicleTableEmptyRow = ({
  statusColumn,
  outOfSyncColumn,
  adminOutOfSyncColumn,
  className,
}: VehicleTableEmptyRowProps) => {
  return (
    <tr className={className}>
      <td className={styles.tableText}>
        <span className={styles.content}>No data avaliable.</span>
      </td>
      <td className={styles.tableText} />
      <td className={styles.tableText} />
      <td className={styles.tableText} />
      <td className={styles.tableText} />
      {statusColumn && <td className={styles.tableTextStatusColumn} />}
      {adminOutOfSyncColumn && (
        <td
          className={styles.tableText}
          data-testid="VehicleTableEmptyRow-adminOutOfSyncColumn"
          id="VehicleTableEmptyRow-adminOutOfSyncColumn"
        />
      )}
      {outOfSyncColumn && <td className={styles.tableText} />}
      <td className={styles.tableText} />
    </tr>
  );
};

export default VehicleTableEmptyRow;
